import {
  Component,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ToastService } from '../../shared/services/toast.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  host: { '[class.ngb-toasts]': 'true' },
  encapsulation: ViewEncapsulation.None
})
export class ToastContainerComponent {

  constructor(
    public toastService: ToastService
  ) { }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

}
