import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from "../../../services/payment/payment.service";



@Component({
  selector: 'app-update-payment-modal',
  templateUrl: './update-payment-modal.component.html',
  styleUrls: ['./update-payment-modal.component.scss']
})
export class UpdatePaymentModalComponent implements OnInit {
  @Input() orderId: string = "";
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public paymentService: PaymentService

  ) { }
  updatePaymentForm: FormGroup;

  ngOnInit() {
    this.initiForm();
  }
  initiForm() {
    this.updatePaymentForm = this.fb.group({
      orderId: [this.orderId, Validators.required],
      remark: ["", Validators.compose([Validators.required])],
      refundOrderId: ["", Validators.required]
    })
  }

  savePayment() {
    if (this.updatePaymentForm.valid) {
      let obj = this.updatePaymentForm.value;
      if (obj.orderId && obj.remark.trim() && obj.refundOrderId.trim()) {
        let reqBody = {
          orderId: obj.orderId,
          remark: `Refund OrderId ${obj.refundOrderId}, due to ${obj.remark}`
        }
        this.paymentService.updateExpire(reqBody).subscribe((res) => {
          let msg = res && res.statusCode ? 'Updated OrderId' : 'Error processing your request';
          this.activeModal.close(msg);
        })
      }
    }
  }
}
