import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }

  // watchboard page related
  searchData = new Subject<any>();

  /**
   * @function setSearchData
   * @description set the search query to subject
   * @param query 
   */
  setSearchData(query: any) {
    this.searchData.next(query);
  }

  userRowsData = new Subject<any>();

  /**
   * @function setuserRowsData
   * @description set the row count to subject
   * @param rowCount 
   */
  setUserRowsData(rowCount: number) {
    this.userRowsData.next(rowCount);
  }


  brokerAndStatus = new BehaviorSubject<boolean>(false);

  /**
   * @function setEmpty
   * @description set value to BehaviorSubject
   * @param value 
   */
  setEmpty(value: boolean) {
    this.brokerAndStatus.next(value);
  }

  // cash-management page related
  cashSearchData = new Subject<any>();

  /**
   * @function setCashSearchData
   * @description set value to BehaviorSubject
   * @param query 
   */
  setCashSearchData(query: any) {
    this.cashSearchData.next(query);
  }

  newCashRowsData = new Subject<any>();

  /**
   * @function setNewCashRowsData
   * @description set the new cash row count to subject
   * @param rowCount 
   */
  setNewCashRowsData(rowCount: number) {
    this.newCashRowsData.next(rowCount);
  }

  newCashFilterData = new BehaviorSubject<boolean>(false);

  /**
   * @function setNewCashEmpty
   * @description set value to BehaviorSubject
   * @param value 
   */
  setNewCashEmpty(value: boolean) {
    this.newCashFilterData.next(value);
  }

  pendingCashSearchData = new Subject<any>();

  /**
   * @function setPendingCashSearchData
   * @description set value to BehaviorSubject
   * @param query 
   */
  setPendingCashSearchData(query: any) {
    this.pendingCashSearchData.next(query);
  }

  pendingCashRowsData = new Subject<any>();

  /**
   * @function setPendingCashRowsData
   * @description set the new Pending row count to subject
   * @param rowCount 
   */
  setPendingCashRowsData(rowCount: number) {
    this.pendingCashRowsData.next(rowCount);
  }

  newPendingCashFilterData = new BehaviorSubject<boolean>(false);

  /**
   * @function setNewPendingCashEmpty
   * @description set value to BehaviorSubject
   * @param value 
   */
  setNewPendingCashEmpty(value: boolean) {
    this.newPendingCashFilterData.next(value);
  }


  // trade-terminal page related
  tradeSearchData = new Subject<any>();

  /**
   * @function setTradeSearchData
   * @description set value to BehaviorSubject
   * @param query 
   */
  setTradeSearchData(query: any) {
    this.tradeSearchData.next(query);
  }

  newTradeRowsData = new Subject<any>();

  /**
   * @function setNewTradeRowsData
   * @description set the new Trade row count to subject
   * @param rowCount 
   */
  setNewTradeRowsData(rowCount: number) {
    this.newTradeRowsData.next(rowCount);
  }

  newTradeFilterData = new BehaviorSubject<boolean>(false);

  /**
   * @function setNewTradeEmpty
   * @description set value to BehaviorSubject
   * @param value 
   */
  setNewTradeEmpty(value: boolean) {
    this.newTradeFilterData.next(value);
  }

  pendingTradeSearchData = new Subject<any>();

  /**
   * @function setPendingTradeSearchData
   * @description set value to BehaviorSubject
   * @param query 
   */
  setPendingTradeSearchData(query: any) {
    this.pendingTradeSearchData.next(query);
  }

  pendingTradeRowsData = new Subject<any>();

  /**
   * @function setPendingTradeRowsData
   * @description set the pending Trade row count to subject
   * @param rowCount 
   */
  setPendingTradeRowsData(rowCount: number) {
    this.pendingTradeRowsData.next(rowCount);
  }

  newPendingTradeFilterData = new BehaviorSubject<boolean>(false);

  /**
   * @function setNewPendingTradeEmpty
   * @description set value to BehaviorSubject
   * @param value 
   */
  setNewPendingTradeEmpty(value: boolean) {
    this.newPendingTradeFilterData.next(value);
  }

  // goal and edit-goal page related
  userGoalData = new BehaviorSubject<any>(null);

  /**
   * @function setUserGoalData
   * @description set user goal data from goal page to edit-goal page
   * @param goals 
   */
  setUserGoalData(goals: Observable<any>) {
    this.userGoalData.next(goals);
  }

  // edit-goal page and trades page related
  userTradeData = new BehaviorSubject<any>(null);

  /**
   * @function setUserTradeData
   * @description set user trade data from edit-goal page to trades page
   * @param trades 
   */
  setUserTradeData(trades: Observable<any>) {
    this.userTradeData.next(trades);
  }

  // leads page

  routeData: BehaviorSubject<string> = new BehaviorSubject<string>('/dashboard/watchboard');

  /**
   * @function setRouteData
   * @description set the route path data
   * @param path 
   */
  setRouteData(path: string) {
    this.routeData.next(path);
  }

  /**
   * @function getUserById
   * @description get user information by their id
   * @param id 
   */
  getUserById(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/watchboard/userDetail/${id}`);
  }

  /**
   * @function getCustomerGoalsById
   * @description get customer goals infirmation by their id
   * @param id 
   */
  getCustomerGoalsById(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/${id}/getcustomergoals`);
  }


  /**
   * @function editGoalByGoalId
   * @description return edit goal information by their id
   * @param id 
   */
  editGoalByGoalId(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/${id}/editGoalByGoalId`);
  }

  /**
   * @function deleteAdminGoal
   * @description delete admin goal
   * @param selectedGoal 
   */
  deleteAdminGoal(selectedGoal): Observable<any> {
    return this.http.post(`${this.baseUrl}/goals/${selectedGoal.id}/admingoaldelete`, selectedGoal);
  }

  // client activation report page

  /**
   * @function getActivatedClient
   * @description get activated client information
   * @param obj 
   */
  getActivatedClient(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/histories/getActiveuser`, obj);
  }

  // AUM report page

  /**
   * @function getAumReport
   * @description get AUM report information
   * @param obj 
   */
  getAumReport(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/histories/getAumReport`, obj);
  }

  // goal count report page

  /**
   * @function getGoalCountReport
   * @description get goal count report information
   * @param obj 
   */
  getGoalCountReport(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/histories/getGoalMakingReport`, obj);
  }

  // trading details page

  /**
   * @function getTradingDetails
   * @description get trading details of tavaga user for specific daterange
   * @param obj 
   */
  getTradingDetails(obj): Observable<any> {
    return this.http.get(`${this.baseUrl}/transactions/${obj.user_id}/tradeDetails/${obj.start_date}/${obj.end_date}`);
  }

  // user-management page

  /**
   * @function getListOfAdminUsers
   * @description get admin users information
   */
  getListOfAdminUsers(): Observable<any> {
    return this.http.get(`${this.baseUrl}/users/listofAdminUsers`, {});
  }

  // edit goal processing page

  /**
   * @function getProcessingGoalData
   * @description get processing goal data records
   * @param limit 
   * @param skip 
   */
  getProcessingGoalData(limit, skip): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/getProcessingGoals/${limit}/${skip}`, {});
  }

  /**
   * @function getProcessingTradeData
   * @description return new trade data
   * @param obj 
   */
  getProcessingTradeData(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/newTrades/newtradesOps`, obj);
  }

  // watchboard page

  /**
   * @function getFilteredData
   * @description return new filtered data
   * @param obj 
   */
  getFilteredData(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/firbaseData/watchboardAdminFilter`, obj);
  }


  /**
   * @function sendPromotionalMail
   * @description send Promotional email to selected users
   * @param query 
   */
  sendPromotionalMail(body: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/sendPromotionalEmail`, body);
  }

  /**
   * @function sendNotifi
   * @description send notifications(simple,blog,video) to selected users
   * @param query 
   */
  sendNotifi(query: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/sendFirebaseNotification`, query);
  }

  /**
   * @function updateUser
   * @description admin update user all details
   * @param query 
   */
  updateUser(query: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/watchboard/editUser`, query);
  }

  // cash-management page

  /**
   * @function getCashFilteredData
   * @description return new cash filtered data
   * @param obj 
   */
  getCashFilteredData(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/getNewCash`, obj);
  }

  /**
   * @function getPendingCashFilteredData
   * @description return new pending cash filtered data
   * @param obj 
   */
  getPendingCashFilteredData(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/GetPendingCashTransactions`, obj);
  }

  /**
   * @function deleteNewCashRecords
   * @description delete edit goal records in cash-management and leads page
   * @param obj 
   */
  editGoalDeletes(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/goals/editgoalDeletes`, obj);
  }

  /**
   * @function generateTransactions
   * @description generate transactions in new cash table
   * @param obj 
   */
  generateTransactions(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/generateCashTransaction`, obj);
  }

  /**
   * @function generateTransactions
   * @description generate transactions in new cash table
   * @param obj 
   */
  deleteCashTransactions(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/deleteCashTransactions`, obj);
  }

  /**
    * @function updateEtfPrice
    * @description update exchange-traded fund(ETF) prices
    * @param etfData 
    */
  updateEtfPrice(etfData: Array<any>): Observable<any> {
    return this.http.put(`${this.baseUrl}/currentEtfs/updateEtfPrice`, etfData);
  }

  /**
   * @function recordCashTransactions
   * @description record/update cash transactions
   * @param selectedIds 
   */
  recordCashTransactions(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/recordCashTxn`, obj);
  }

  /**
   * @function runEomScript
   * @description run the End Of Month(EOM) script
   * @param ids 
   */
  runEomScript(ids): Observable<any> {
    const sc = JSON.stringify(ids);
    return this.http.post(`${this.baseUrl}/currentEtfs/${ids}/endOfMonthScripts`, sc);
  }

  /**
   * @function getEtfDetails
   * @description get exchange-traded fund(ETF) information
   */
  getEtfDetails(): Observable<any> {
    return this.http.get(`${this.baseUrl}/currentEtfs/getEtfDetails`, {});
  }


  // trade terminal page

  /**
   * @function getTradeFilteredData
   * @description return new trade filtered data
   * @param obj 
   */
  getTradeFilteredData(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/trades/GetNewTrades`, obj);
  }

  /**
   * @function getPendingCashFilteredData
   * @description return new pending cash filtered data
   * @param obj 
   */
  getPendingTradeFilteredData(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/trades/getPendingTrades`, obj);
  }

  /**
   * @function deleteNewTradeEditId
   * @description delete edit goal new records in trade terminal page
   * @param obj 
   */
  deleteNewTradeEditId(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/UndoRecordedCashTransaction`, obj);
  }

  /**
   * @function generateTradeTransactions
   * @description generate trade transactions
   * @param selectedTrades 
   */
  generateTradeTransactions(obj): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/generateTrades`, obj);
  }

  /**
   * @function recordTransanctions
   * @description update the records quantity and price in trade terminal page
   * @param tradeData 
   */
  recordTransanctions(tradeData): Observable<any> {
    return this.http.post(`${this.baseUrl}/newTrades/recordTrades`, tradeData);
  }

  /**
    * @function getAdminNotification
    * @description 
    */
  getAdminNotification(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/getAdminNotification`, reqBody);
  }


  /**
   * @function generateEOMForUser
   * @description 
   */
  generateEOMForUser(reqBody:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/generateEOMFor`,reqBody);
  }
}
