import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class httpInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private spinnerService: SpinnerService
    ) { }

    /**
     * @function intercept
     * @description Set the authorization token to each request except login request
     * @param request 
     * @param newRequest 
     */
    intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {

        //Get Token data from local storage
        let tokenInfo = JSON.parse(localStorage.getItem('access_token'));

        if (tokenInfo && tokenInfo.id) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${tokenInfo.id}`,
                    'Content-Type': 'application/json'
                }
            });
        }

        return newRequest.handle(request).pipe(tap((event: HttpEvent<any>) => {
            // console.log(event);

            if (event instanceof HttpResponse) {
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this.spinnerService.stop();
                    this.auth.logout();
                }
            }
        }));
    }
}