import { 
  Component, 
  OnInit
 } from '@angular/core';
 import { 
   NgxDrpOptions, 
   PresetItem, 
   Range 
 } from 'ngx-mat-daterange-picker';
import { PageTitleService } from 'src/app/services/page-title.service';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';
import * as moment from "moment";
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';

@Component({
  selector: 'app-aum-report',
  templateUrl: './aum-report.component.html',
  styleUrls: ['./aum-report.component.scss']
})
export class AumReportComponent implements OnInit {

  data: any[];
  filterQuery = "";
  rowsOnPage = 20;

  // date range picker related
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  itemsPerPage: number = 10;
  range: Range = { fromDate: new Date(), toDate: new Date() };

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private router: Router) { }

  /**
   * @function ngOnInit
   * @description Set header title
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.pageTitleService.setTitle('AUM Report Summary');

    const today = new Date();
    const yester = new Date(new Date().setDate(new Date().getDate() - 30));
    this.setupPresets();

    this.options = {
      presets: this.presets,
      format: "mediumDate",
      range: {
        fromDate: yester,
        toDate: today
      },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false
      }
    };

  }

  /**
   * @function getAumReport
   * @description get AUM report details of selected daterange
   * @param dateObj
   */
  getAumReport(dateObj) {
    this.spinnerService.start();
    this.userService.getAumReport(dateObj).subscribe(
      data => {
        if (data["aumReport"].length > 0) {
          this.data = data["aumReport"];
          this.spinnerService.stop();
        } else {
          this.data = [];
          this.toastService.show(`no record found`, { classname: "bg-danger text-light", delay: 5000 });
          this.spinnerService.stop();
        }
      },
      err => {
        this.spinnerService.stop();
      }
    );
  }

  /**
   * @function updateRange
   * @description handler function that receives the updated date range object
   * @param range
   */
  updateRange(range: Range) {
    this.range = range;
    let dateObj = {
      fromDate: moment(this.range.fromDate).format("YYYY-MM-DD"),
      toDate: moment(this.range.toDate).format("YYYY-MM-DD")
    };
    this.getAumReport(dateObj);
  }

  /**
   * @function setupPresets
   * @description helper function to create initial presets
   */
  setupPresets() {
    const backDate = numOfDays => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: "Yesterday",
        range: { fromDate: yesterday, toDate: today }
      },
      {
        presetLabel: "Last 7 Days",
        range: { fromDate: minus7, toDate: today }
      },
      {
        presetLabel: "Last 30 Days",
        range: { fromDate: minus30, toDate: today }
      },
      {
        presetLabel: "This Month",
        range: { fromDate: currMonthStart, toDate: currMonthEnd }
      },
      {
        presetLabel: "Last Month",
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }

  /**
   * @function downloadReport
   * @description download report in excel
   */
  downloadReport() {
    let report = [...this.data];
    let reportArr = [];
    report.forEach(element => {
      reportArr.push({
        DATE: element.date ? element.date : '-',
        RRSLGETF: element.obj.rrslgetf ? element.obj.rrslgetf : '-',
        SETFNIF50: element.obj.setfnif50.toFixed(4) ? element.obj.setfnif50.toFixed(4) : '-',
        KOTAKGOLD: element.obj.kotakgold.toFixed(4) ? element.obj.kotakgold.toFixed(4) : '-',
        M100: element.obj.m100.toFixed(4) ? element.obj.m100.toFixed(4) : '-',
        N100: element.obj.n100.toFixed(4) ? element.obj.n100.toFixed(4) : '-',
        JUNIORBEES: element.obj.juniorbees.toFixed(4) ? element.obj.juniorbees.toFixed(4) : '-',
        UNIQ_CUSTOMERS: element.uniqCustomer ? element.uniqCustomer : '-'
      });
    });

    this.excelService.exportAsExcelFile(reportArr, moment(this.range.fromDate).format("YYYY-MM-DD") +
      "-to-" + moment(this.range.toDate).format("YYYY-MM-DD") + "-AUM-report-summary");
  }

}