import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent implements OnInit {

  editTradeData = [];
  filterQuery = "";
  rowsOnPage = 10;

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private spinnerService: SpinnerService,
    private router: Router
  ) { }

  /**
   * @function ngOnInit
   * @description call to getEditedTrades() function
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.getEditedTrades();
  }

  /**
   * @function getEditedTrades
   * @description get edited trades
   */
  getEditedTrades() {
    this.userService.userTradeData.subscribe((res) => {
      if (res) {
        this.pageTitleService.setTitle(res['name'] + ' Trade Details');
        this.editTradeData = res['newTrades'];
      } else {
        this.router.navigate(["dashboard/reports/trading-details/edit-goals"]);
      }
    })
  }

  /**
   * @function goToEditGoalPage
   * @description navigate to edit goal page
   */
  goToEditGoalPage() {
    this.router.navigate(["dashboard/reports/trading-details/edit-goals"]);
  }

  /**
   * @function downloadReport
   * @description download report in excel
   */
  downloadReport() {
    let report = [...this.editTradeData];
    let reportArr = [];
    report.forEach(element => {
      reportArr.push({
        ID: element.goalId ? element.goalId : '-',
        EDIT_GOAL_ID: element.editGoalId ? element.editGoalId : '-',
        TRADE_ID: element.tradeId ? element.tradeId : '-',
        BASKET_ID: element.basketId ? element.basketId : '-',
        INSTRUMENT_ID: element.instrumentId ? element.instrumentId : '-',
        BROKERAGE: element.brokerage ? element.brokerage : '-',
        GOAL_STATUS: element.id ? element.id : '-',
        TRADES_STATUS: element.tradesStatus ? element.tradesStatus : '-',
        TRADE: element.trade ? element.trade : '-',
        INVESTED_AMOUNT: element.investedAmount ? element.investedAmount : '-',
        NET_PRICE: element.netPrice ? element.netPrice : '-',
        QUANTITY: element.quantity ? element.quantity : '-',
        TRADE_DATE: element.tradeDate ? element.tradeDate : '-',
        UPDATE_AT: element.updatedAt ? element.updatedAt : '-',
        ACTUAL_PRICE: element.actualPrice ? element.actualPrice : '-',
        ACTUAL_QUANTITY: element.actualQuantity ? element.actualQuantity : '-',
        EXCHANGE_TIMESTAMP: element.exchange_timestamp ? element.exchange_timestamp : '-',
        ORDER_TIMESTAMP: element.order_timestamp ? element.order_timestamp : '-',
        STATUS_MESSAGE: element.status_message ? element.status_message : '-',
        ARCADIA_ID: element.arcadiaId ? element.arcadiaId : '-',
      });
    });

    this.excelService.exportAsExcelFile(reportArr, "" + "-trades-records");
  }

}
