import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TradeTerminalService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  /**
  * @function getbasketAllocationcounts
  * @description 
  */
  getBasketAllocationCount(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/getTradesNewCount`, reqBody);
  }


  /**
* @function getBasketList
* @description 
*/
  getBasketList(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/getTradesNew`, reqBody);
  }

  /**
* @function generateTrades
* @description Generate trades request body accepts only goalId if elements are present then trades are generated for those, if passed empty then all trades are generated
*/
  generateTrades(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/generateTradesNew`, reqBody);
  }

  /**
* @function generateTrades
* @description Generate trades request body accepts only goalId if elements are present then trades are generated for those, if passed empty then all trades are generated
*/
  deleteTrades(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/deleteTradesNew`, reqBody);
  }

  downloadTrades(reqBody = {}): Observable<any> {
    return this.http.post(`${this.baseUrl}/internal/download/basket-allocations`, {}, {
      headers: {
        'api-key': 'q%Z3LvbaWj}`$3@t'
      },
      responseType: 'blob' 
    })
  }

}
