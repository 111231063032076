import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-multiple-filter-modal',
  templateUrl: './multiple-filter-modal.component.html',
  styleUrls: ['./multiple-filter-modal.component.scss']
})
export class MultipleFilterModalComponent implements OnInit {

  @Input() selectedBrokers = [];
  @Input() selectedAppStatus = [];
  @Output() userOptionData = new EventEmitter<any>();

  filterQuery = "";

  brokers = ["zerodha", "edelweiss", "upstox", "lm securities",
    "ICICI Direct", "tradesmart", "HDFC Sec", "SBI Capital",
    "Axis Direct", "Kotak Sec", "Yes Sec", "Motilal Oswal",
    "Angel Broking", "sharekhan", "5Paisa", "Religare",
    "IDBI Capital", "Indiabulls", "Karvy"];

  statusOptions = [
    "activated",
    "phone verify",
    "broker choosen",
    "goal set",
    "payment done",
    "just email",
    "risk game played"
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService
  ) { }

  /**
   * @function ngOnInit
   * @description empty the array if response is true
   */
  ngOnInit() {
    this.userService.brokerAndStatus.subscribe((res) => {
      if (res == true) {
        this.selectedBrokers = [];
        this.selectedAppStatus = [];
      }
    })
  }

  /**
 * @function onBrokerChange
 * @description when broker change function is called
 * @param value
 */
  onBrokerChange(value) {
    let i = this.selectedBrokers.indexOf(value);
    if (i != -1) {
      this.selectedBrokers.splice(i, 1);
    } else {
      this.selectedBrokers.push(value);
    }
  }

  /**
   * @function onAppStatusChange
   * @description when app status change function is called
   * @param value
   */
  onAppStatusChange(value) {
    let i = this.selectedAppStatus.indexOf(value);
    if (i != -1) {
      this.selectedAppStatus.splice(i, 1);
    } else {
      this.selectedAppStatus.push(value);
    }
  }


  /**
   * @function dataPassToParent
   * @description stored data send to parent via emit() method
   */
  dataPassToParent() {
    let selectedData = {
      "selectedBrokers": this.selectedBrokers,
      "selectedAppStatus": this.selectedAppStatus
    };

    this.userOptionData.emit(selectedData);
    this.activeModal.close('Ok click');
  }

}
