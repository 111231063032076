import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { PageTitleService } from "src/app/services/page-title.service";
import { NotificationModalComponent } from "./notification-modal/notification-modal.component";
import { ToastService } from "src/app/core/shared/services/toast.service";
import { NgbModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { WatchboardService } from '../../services/watchboard/watchboard.service';
import { Subject } from 'rxjs';
import { ExcelService } from "src/app/services/excel.service";
import * as _ from 'lodash';


@Component({
    selector: "app-watchboard",
    templateUrl: "./watchboard.component.html",
    styleUrls: ["./watchboard.component.scss"]
})
export class WatchboardComponent implements OnInit {

    @ViewChild('appStatusOption', { static: false }) appStatusOption: ElementRef;
    activeTemplates = [];
    mfDataTable = [];

    filterSearch = new Subject<string>();
    dropdownSettings: IDropdownSettings = {};

    notificationModaloptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: true,
        centered: true,
        size: 'lg',
    };
    selectAll: boolean = false;
    usersCount = 0;
    usersList = [];
    apiCall: boolean = false;
    downloadSpinner = false;
    limitedSelected = false;
    selectedTavagaId = [];
    brokerList = [];

    selectedFilters = {
        filters: {
            onboardingStatus: null,
            tavagaId: null,
            name: null,
            email: null,
            phone: null,
            brokerList: [],
            deviceType: null,
            limit: null,
            paymentStatus: null,
            kycStatus: null
        },
        limit: 30,
        skip: 0,
        currentPage: 1,
    };

    filters: any = {};
    mappings = {
        deviceType: {},
        onboardingStatus: {},
        kycStatus: {},
        paymentStatus: {}
    };

    paymentUI = {
        notDone: {
            icon: 'cancel',
            class: 'text-danger'
        },
        active: {
            icon: 'check_circle',
            class: 'text-success'
        },
        expired: {
            icon: 'error',
            class: 'text-warning'
        }
    };


    constructor(
        private pageTitleService: PageTitleService,
        private watchboardService: WatchboardService,
        private toastService: ToastService,
        private ngmodalService: NgbModal,
        private excelService: ExcelService
    ) { }

    /**
     * @function ngOnInit
     * @description Set header title
     */
    ngOnInit() {
        document.documentElement.scrollTop = 0;
        this.pageTitleService.setTitle("Watchboard");
        this.getMasterData();
        this.getActiveTemplate();
        this.getUsersList();
        this.debounceSearch();
        this.initPlugin();
    }
    initPlugin() {
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'text',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            enableCheckAll: false
        };
    }
    getActiveTemplate() {
        this.watchboardService.getActiveTemplates().subscribe((res) => {
            if (res && res.status_code) {
                this.activeTemplates = res.data;
            }
        });
    }

    getMasterData() {
        this.watchboardService.getMasterData().subscribe(res => {
            const { filters = {} } = res;

            _.forOwn(filters, (fData, fType) => {
                let mapping = {};
                let filter = [];
                fData.forEach(element => {
                    mapping = { ...mapping, ...element };
                    _.forOwn(element, (value, key) => {
                        filter.push(key);
                    })
                });
                this.mappings[fType] = mapping;
                this.filters[fType] = filter;
            });
        });
    }

    getUsersList() {
        this.apiCall = true;
        this.limitedSelected = false;
        this.watchboardService.getUserFilteredData(this.selectedFilters).subscribe((res) => {
            if (res && res.data) {
                this.apiCall = false;
                this.usersList = res.data;
                this.selectAll && this.eventSelectLimited(this.selectAll);
                this.limitedSelected = this.selectAll ? this.selectAll : this.limitedSelected;
            }
        });

        this.watchboardService.getUserCount(this.selectedFilters).subscribe((res) => {
            if (res) {
                this.usersCount = res.count;
            }
        });

        if(!this.brokerList.length){
            this.watchboardService.getUserBrokers().subscribe((res)=>{
                if(res && Array.isArray(res) && res.length){
                    this.brokerList = res.map(brokerName=> {
                        return {
                            id: brokerName,
                            text: brokerName
                        }
                    })
                }
            })
        }
    }

    onBrokerFilterChange(event, checked) {
        if (checked) {
            this.selectedFilters.filters.brokerList.push(event);
        } else {
            this.selectedFilters.filters.brokerList = this.selectedFilters.filters.brokerList.filter((b) => b != event);
        }

        this.getUsersList();
    }

    eventSelectLimited(isSelected) {
        if (this.usersList) {
            this.usersList.forEach(user => {
                let tavagaId = user.tavagaId;
                if (isSelected && !this.selectedTavagaId.includes(tavagaId)) {
                    this.selectedTavagaId.push(tavagaId);
                } if (!isSelected) {
                    this.selectedTavagaId = this.selectedTavagaId.filter((s) => s != tavagaId);
                }
            });
        }
    }

    debounceSearch() {
        // Debounce search.
        this.filterSearch.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe(value => {
                this.selectedFilters.currentPage = 1;
                this.selectedFilters.limit = 30;
                this.selectedFilters.skip = 0;
                this.limitedSelected = false;
                this.selectAll = false;
                this.getUsersList();
            });
    }

    // Code for toggle functionality 
    // paymentFlagChange(event) {
    //     const { checked } = event.target;
    //     const kycStatus = checked ? 'done' : 'notDone';
    //     this.userFilter.filters.kycStatus = kycStatus;
    //     this.getUsersList();
    // }

    changeLimit(event: any) {
        this.selectedFilters.currentPage = 1;
        this.selectedFilters.skip = 0;
        this.getUsersList();
    }

    eventSelectAll() {
        this.selectAll = !this.selectAll;
        this.selectAll && this.eventSelectLimited(this.selectAll);
        //On clear selection uncheck previous page selected and set current page selected only
        if (!this.selectAll) {
            let usersIds = [];
            this.usersList.forEach(user => {
                let userId = user.tavagaId;
                usersIds.push(userId);
            });
            this.selectedTavagaId = usersIds;
        }
    }

    eventInvidiualUser(event: any, tavagaId: number) {
        const { checked } = event.target;
        this.limitedSelected = false;
        this.selectAll = false;
        if (checked) {
            this.selectedTavagaId.push(tavagaId);
        } else {
            this.selectedTavagaId = this.selectedTavagaId.filter((s) => s != tavagaId);
        }
    }

    isSelected(tavagaId) {
        return this.selectedTavagaId.includes(tavagaId);
    }

    resetFilters() {
        this.selectedFilters = {
            filters: {
                onboardingStatus: null,
                tavagaId: null,
                name: null,
                email: null,
                phone: null,
                brokerList: [],
                deviceType: null,
                limit: null,
                paymentStatus: null,
                kycStatus: null
            },
            limit: 30,
            skip: 0,
            currentPage: 1,
        };

        this.selectedTavagaId = [];
        this.getUsersList();
    }

    onPageChange(pageIndex: any) {
        this.selectedFilters.currentPage = pageIndex;
        this.selectedFilters.skip = (pageIndex - 1) * this.selectedFilters.limit;
        this.getUsersList();
    }


    openNotificationDialog() {
        if (this.generateFilterSearch()) {
            const confirmModalRef = this.ngmodalService.open(NotificationModalComponent, this.notificationModaloptions);
            confirmModalRef.componentInstance.filterSearch = this.selectedFilters;
            confirmModalRef.componentInstance.templates = this.activeTemplates;
            confirmModalRef.result.then((result) => {
                this.toastService.show(`Request under process`, { classname: "bg-success text-light", delay: 8000 });
                this.resetFilters();
            }, (reason) => { });
        }
    }

    generateFilterSearch() {
        let status = true;
        if (this.usersList && this.selectAll) {
            this.selectedFilters.limit = this.usersCount;
            this.selectedFilters.skip = 0;
        } else if (this.usersList && (this.selectedTavagaId.length || this.limitedSelected)) {
            this.selectedFilters.filters.tavagaId = this.selectedTavagaId;
            this.selectedFilters.limit = this.selectedTavagaId.length;
            this.selectedFilters.skip = 0;
        } else {
            status = false;
            this.toastService.show(`Please Select Tavaga Id's For Communication`, { classname: "bg-danger text-light", delay: 8000 });
        }
        return status;
    }

    downloadUserData() {
        this.downloadSpinner = true;
        this.watchboardService.downloadUserData(this.selectedFilters).subscribe((s: any) => {
            this.excelService.saveAsExcelFile(s, 'watchboard_data.xlsx');
            this.downloadSpinner = false;
        });
    }
}
