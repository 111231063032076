import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TavagaNgbootstrapModule } from './shared/tavaga-ngbootstrap/tavaga-ngbootstrap.module';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { ConfirmModalComponent } from './dialogs/confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [
    ToastContainerComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    TavagaNgbootstrapModule
  ],
  exports: [
    TavagaNgbootstrapModule,
    ToastContainerComponent
  ],
  entryComponents: [
    ConfirmModalComponent
  ]
})
export class SharedModule { }
