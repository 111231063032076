import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbToastModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbModal,
  NgbModalModule,
  NgbDropdownModule,
  NgbCollapseModule,
  NgbAccordionModule
} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgbToastModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbAccordionModule
  ],
  exports: [
    NgbToastModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbAccordionModule
  ]
})
export class TavagaNgbootstrapModule { }
