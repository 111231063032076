import {
  Component,
  OnInit
} from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent implements OnInit {

  data: any[];
  filterQuery = "";
  rowsOnPage = 20;

  //row selection related
  isSelectedRow1: number;

  // checkbox related
  selectedAll: any;
  selected: false;

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private router: Router
  ) { }

  /**
   * @function ngOnInit
   * @description Set header title
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;

    this.pageTitleService.setTitle('User Management');

    this.userService.getListOfAdminUsers().subscribe((res) => {
      if (res) {
        this.data = res["listofAdminUsers"];
      }
    })

  }

  /**
   * @function selectAll
   * @description for checked and unchecked all checkboxes data table
   */
  selectAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].selected = this.selectedAll;
    }
  }

  /**
   * @function onSelectRow1
   * @description for dynamic class added to row after selecting row
   * @param index1
   */
  onSelectRow1(index1) {
    this.isSelectedRow1 = index1;
  }

  /**
   * @function goToCreateNewUserPage
   * @description navigate to create new user page
   */
  goToCreateNewUserPage() {
    this.router.navigate(["/dashboard/user-management/create-new-user"]);
  }

}
