import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return moment(value).format('MMMM Do [at] h:mm:ss a')
  }

}
