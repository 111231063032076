import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { PaymentService } from "src/app/services/payment/payment.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { CoupounService } from "src/app/services/coupoun/coupoun.service";
import { ToastService } from "src/app/core/shared/services/toast.service";
import { SpinnerService } from "src/app/services/spinner.service";

@Component({
  selector: "app-create-payment-link-modal",
  templateUrl: "./create-payment-link-modal.component.html",
  styleUrls: ["./create-payment-link-modal.component.scss"],
})
export class CreatePaymentLinkModalComponent implements OnInit {
  @Input() userId: number;
  @Input() paymentPlans: any;

  paymentPackage: any;
  paymentLinkForm: FormGroup;
  error: string;
  showSpinner = false;

  isCouponCodeValid = true;

  constructor(
    public activeModal: NgbActiveModal,
    private paymentService: PaymentService,
    private coupounService: CoupounService,
    private toastService: ToastService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.paymentLinkForm = new FormGroup({
      paymentPlan: new FormControl("1", Validators.required),
      couponCode: new FormControl("", [Validators.minLength(6)]),
      sendMail: new FormControl(true),
      sendSms: new FormControl(true),
    });

    this.paymentPackage = {
      amountInPaisa:
        this.paymentPlans.find(
          (plan) => plan.id == this.paymentLinkForm.get("paymentPlan").value
        ).amount * 100,
      discount: 0,
    };

    this.changeEvent();
  }

  createPaymentLink() {
    this.showSpinner = true;
    const reqBody = {
      userId: this.userId,
      paymentFrequencyId: this.paymentLinkForm.get("paymentPlan").value,
      sendEmail: true,
      discountCode: this.paymentLinkForm.get("couponCode").value,
      sendSms: this.paymentLinkForm.get("sendSms").value,
    };

    this.paymentService.createPaymentLink(reqBody).subscribe(
      (res) => {
        this.activeModal.close();
        this.toastService.show("Payment link sent !!", {
          classname: "bg-success text-light",
          delay: 5000,
        });
      },
      (e) => {
        this.showSpinner = false;
        const error = e.error;
        this.error = error
          ? error.error
            ? error.error.message
            : error.message
          : e.message;
        console.log(this.error);
        this.toastService.show(this.error, {
          classname: "bg-danger text-light",
          delay: 10000,
        });
      }
    );
  }

  changeEvent() {
    let couponCodeVal = this.paymentLinkForm.get("couponCode");
    couponCodeVal.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val) => {
        if (val.length >= 6) {
          let tempVal = val.toUpperCase();
          this.evaluatePaymentPackage({ discountCode: tempVal });
        } else if (val.length === 0) {
          this.isCouponCodeValid = true;
          this.evaluatePaymentPackage({ discountCode: undefined });
        } else {
          this.isCouponCodeValid = true;
        }
      });
  }

  onPlanChange(e) {
    this.evaluatePaymentPackage({ paymentFrequencyId: e });
  }

  evaluatePaymentPackage({
    discountCode = this.paymentLinkForm.get("couponCode").value || undefined,
    paymentFrequencyId = this.paymentLinkForm.get("paymentPlan").value,
  } = {}) {
    this.coupounService
      .getPaymentPackage({ discountCode, paymentFrequencyId })
      .subscribe(
        (res) => {
          this.paymentPackage = res;
          this.isCouponCodeValid = true;
        },
        (error) => {
          this.paymentPackage = {
            amountInPaisa: 0,
            discount: 0,
          };
          if (this.paymentLinkForm.get("couponCode").value) {
            this.isCouponCodeValid = false;
          }
        }
      );
  }
}
