import {
  Component,
  OnInit
} from '@angular/core';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import {
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { PageTitleService } from 'src/app/services/page-title.service';
import { Router } from "@angular/router";
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';
import { NewTradeFilterModalComponent } from './new-trade-filter-modal/new-trade-filter-modal.component';
import { PendingTradeFilterModalComponent } from './pending-trade-filter-modal/pending-trade-filter-modal.component';
import { ConfirmModalComponent } from 'src/app/core/dialogs/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-trade-terminal',
  templateUrl: './trade-terminal.component.html',
  styleUrls: ['./trade-terminal.component.scss']
})
export class TradeTerminalComponent implements OnInit {

  p1: number = 1;
  userCount1: number;
  filterQuery1 = "";
  rowsOnPage1 = 5;
  searchQuery1: any;
  totalPagesCount1: number;
  prePage1: any;
  nextPage1: any;

  p2: number = 1;
  userCount2: number;
  filterQuery2 = "";
  rowsOnPage2 = 50;
  searchQuery2: any;
  sortOrder = "desc";
  sortBy = "id";
  totalPagesCount2: number;
  prePage2: any;
  nextPage2: any;

  // for editing data in table
  isEditable: boolean = false;
  isEditableForRecord: boolean = false;

  // checkbox related
  selectedAll: any;
  selected: false;

  selectedAll2: any;
  selected2: false;

  newTradeData = [];
  pendingTradeData = [];

  // for selected edit ids
  selectedEditIds = [];

  // for selected edit ids in pending records
  selectedPendingEditIds = [];

  //row selection related
  isSelectedRow1: number;
  isSelectedRow2: number;

  // upload file related
  file: File;

  // for download generated trade data in EXCEL
  generatedExcelData = [];

  confirmModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    size: 'sm',
    centered: true,
  };

  filterModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    centered: true,
  };

  //new trade filter related
  selectedNewTradeBrokersList = [];

  //pending trade filter related
  selectedPendingTradeBrokersList = [];

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private router: Router,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private ngmodalService: NgbModal
  ) { }

  /**
   * @function ngOnInit
   * @description Set header title
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.pageTitleService.setTitle('Trade Terminal');

    this.searchQuery1 = {
      filterData: {
        limit: this.rowsOnPage1,
        skip: this.p1,
        broker: [],
        query: this.filterQuery1
      }
    }

    this.searchQuery2 = {
      limit: this.rowsOnPage2,
      skip: this.p2,
      broker: [],
      query: this.filterQuery2
    }

    this.userService.tradeSearchData.pipe(debounceTime(1000),
      distinctUntilChanged()).subscribe((res) => {
        this.filterQuery1 = res;
        this.searchQuery1.filterData.query = this.filterQuery1;
        this.getTradeFilteredData(this.searchQuery1);
      })

    this.userService.newTradeRowsData.pipe(debounceTime(1000),
      distinctUntilChanged()).subscribe((res) => {
        this.rowsOnPage1 = Number(res);
        this.searchQuery1.filterData.limit = this.rowsOnPage1;
        this.getTradeFilteredData(this.searchQuery1);
      })

    this.userService.pendingTradeSearchData.pipe(debounceTime(1000),
      distinctUntilChanged()).subscribe((res) => {
        this.filterQuery2 = res;
        this.searchQuery2.query = this.filterQuery2;
        this.getPendingTradeFilteredData(this.searchQuery2);
      })

    this.userService.pendingTradeRowsData.pipe(debounceTime(1000),
      distinctUntilChanged()).subscribe((res) => {
        this.rowsOnPage2 = Number(res);
        this.searchQuery2.limit = this.rowsOnPage2;
        this.getPendingTradeFilteredData(this.searchQuery2);
      })

    this.getTradeFilteredData(this.searchQuery1);
  }


  /**
  * @function getNewCashRecords
  * @description for get new cash records in cash-management page
  */
  getNewTradeRecords() {
    this.selectedEditIds = [];
    this.selectedAll = false;

    this.filterQuery1 = "";
    this.userService.setNewTradeEmpty(true);
    this.selectedNewTradeBrokersList = [];
    this.rowsOnPage1 = 5;
    this.p1 = 1;
    this.searchQuery1.filterData.limit = this.rowsOnPage1;
    this.searchQuery1.filterData.skip = this.p1;
    this.searchQuery1.filterData.broker = [];
    this.searchQuery1.filterData.query = this.filterQuery1;

    this.userService.setTradeSearchData(this.filterQuery1);
    this.getTradeFilteredData(this.searchQuery1);
  }

  /**
    * @function getPendingTradeRecords
    * @description for get new pending trade records in trade terminal page
    */
  getPendingTradeRecords() {
    this.selectedPendingEditIds = [];
    this.selectedAll2 = false;

    this.filterQuery2 = "";
    this.userService.setNewPendingTradeEmpty(false);
    this.selectedPendingTradeBrokersList = [];
    this.rowsOnPage2 = 50;
    this.p2 = 1;
    this.searchQuery2.limit = this.rowsOnPage2;
    this.searchQuery2.skip = this.p2;
    this.searchQuery2.broker = [];
    this.searchQuery2.query = this.filterQuery2;
    // disable editing value
    this.isEditable = false;

    this.userService.setPendingTradeSearchData(this.filterQuery2);
    this.getPendingTradeFilteredData(this.searchQuery2);
  }


  /**
 * @function onPageChange1
 * @description on page change event fire,return current page number
 * @param activePage 
 */
  onPageChange1(activePage1) {
    document.documentElement.scrollTop = 0;
    this.p1 = activePage1
    this.searchQuery1.filterData.skip = this.p1;
    this.searchQuery1.filterData.limit = this.rowsOnPage1;

    this.getTradeFilteredData(this.searchQuery1);
  }

  /**
* @function goToFirstPage
* @description go to the first page
*/
  goToFirstPage1() {
    this.p1 = 1;
    this.searchQuery1.filterData.skip = this.p1;

    this.getTradeFilteredData(this.searchQuery1);
  }

  /**
   * @function goToLastPage
   * @description go to the last page
   */
  goToLastPage1() {
    this.p1 = this.totalPagesCount1;
    this.searchQuery1.filterData.skip = this.p1;

    this.getTradeFilteredData(this.searchQuery1);
  }

  /**
    * @function onPageChange2
    * @description on page change event fire,return current page number
    * @param activePage2 
    */
  onPageChange2(activePage2) {
    this.p2 = activePage2
    this.searchQuery2.skip = this.p2;
    this.searchQuery2.limit = this.rowsOnPage2;

    this.getPendingTradeFilteredData(this.searchQuery2);
  }

  /**
    * @function goToFirstPage2
    * @description go to the first page
    */
  goToFirstPage2() {
    this.p2 = 1;
    this.searchQuery2.skip = this.p2;

    this.getPendingTradeFilteredData(this.searchQuery2);
  }

  /**
    * @function goToLastPage2
    * @description go to the last page
    */
  goToLastPage2() {
    this.p2 = this.totalPagesCount2;
    this.searchQuery2.skip = this.p2;

    this.getPendingTradeFilteredData(this.searchQuery2);
  }

  /**
   * @function selectAll
   * @description for checked and unchecked all checkboxes in trade table
   */
  selectAll() {
    this.selectedEditIds = [];

    this.newTradeData.map((item) => {
      item.selected = this.selectedAll;
    })

    console.log(this.selectedEditIds);
  }

  /**
   * @function checkIfAllSelected
   * @description for checked and unchecked checkboxes related to editGoalId in cash table
   * @param editGoalId
   */
  checkIfAllSelected(editGoalId: number) {
    let i = this.selectedEditIds.indexOf(editGoalId);
    if (i != -1) {
      this.selectedEditIds.splice(i, 1);
    } else {
      this.selectedEditIds.push(editGoalId);
    }

    this.selectedAll = this.newTradeData.every((item: any) => {
      return item.selected == true;
    });

    this.newTradeData.forEach(tradeData => {
      if (this.selectedEditIds.includes(tradeData.editGoalId)) {
        tradeData.selected = true;
      } else {
        tradeData.selected = false;
        this.selectedAll = false;
      }
    });

    console.log(this.selectedEditIds);
  }

  /**
    * @function selectAll2
    * @description for checked and unchecked all checkboxes in trade transanction table
    */
  selectAll2() {
    this.selectedPendingEditIds = [];

    this.pendingTradeData.map((item) => {
      item.selected2 = this.selectedAll2;
    })

    console.log(this.selectedPendingEditIds);
  }

  /**
   * @function checkIfAllSelected2
   * @description for checked and unchecked checkboxes related to editGoalId in trade transanction table
   * @param editGoalId
   */
  checkIfAllSelected2(editGoalId: number) {
    let i = this.selectedPendingEditIds.indexOf(editGoalId);
    if (i != -1) {
      this.selectedPendingEditIds.splice(i, 1);
    } else {
      this.selectedPendingEditIds.push(editGoalId);
    }

    this.selectedAll2 = this.pendingTradeData.every((item: any) => {
      return item.selected2 == true;
    });

    this.pendingTradeData.forEach(tradeData => {
      if (this.selectedPendingEditIds.includes(tradeData.editGoalId)) {
        tradeData.selected2 = true;
      } else {
        tradeData.selected2 = false;
        this.selectedAll2 = false;
      }
    });

    console.log(this.selectedPendingEditIds);
  }

  /**
   * @function incomingfile
   * @description get uploaded file
   * @param event
   */
  incomingfile(event) {
    this.file = event.target.files[0];
    console.log(event);
  }

  /**
   * @function onSelectRow1
   * @description for dynamic class added to row after selecting row
   * @param index1
   */
  onSelectRow1(index1) {
    this.isSelectedRow1 = index1;
  }

  /**
   * @function onSelectRow2
   * @description for dynamic class added to row after selecting row
   * @param index2
   */
  onSelectRow2(index2) {
    this.isSelectedRow2 = index2;
  }

  /**
   * @function goToLeadsPage
   * @description for navigate to leads page with tavaga-id
   * @param tavagaid
   */
  goToLeadsPage(tavagaid) {
    this.userService.setRouteData('/dashboard/trade-terminal');
    this.router.navigate(["/dashboard/leads", tavagaid]);
  }

  /**
   * @function editPrices
   * @description making editable data in table
   */
  editPrices() {
    this.isEditable = true;
  }

  /**
   * @function cancelUpdate
   * @description cancel to update the prices
   */
  cancelUpdate() {
    this.isEditable = false;
  }


  /**
   * @function getSelectedRecordsForDelete
   * @description returns only editGoalId of checked records in trade table for delete records
   */
  getSelectedRecordsForDelete(): Array<any> {
    return this.newTradeData.filter((tradeData) => {
      return tradeData.selected == true
    }).map((data) => data.editGoalId)
  }

  /**
   * @function getSelectedRecordsForGenerateTrans
   * @description returns checked records in trade table for generate transanction
   */
  getSelectedRecordsForGenerateTrans(): Array<any> {
    return this.newTradeData.filter((tradeData) => {
      return tradeData.selected == true
    })
  }

  /**
   * @function deleteNewTradeRecords
   * @description delete one or multiple selected cash records
   */
  deleteNewTradeRecords() {
    const deleteData = {
      editGoalList: this.selectedEditIds,
      filterData: {
        query: this.filterQuery1,
        limit: this.userCount1,
        skip: 0,
        broker: this.selectedNewTradeBrokersList
      }
    };

    this.searchQuery1 = {
      filterData: {
        limit: this.rowsOnPage1,
        skip: this.p1,
        broker: this.selectedNewTradeBrokersList,
        query: this.filterQuery1
      }
    }

    if (this.selectedEditIds.length || this.selectedAll) {

      const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
      confirmModalRef.componentInstance.title = "Confirm Dialog";
      confirmModalRef.componentInstance.body = "Are You Sure To Delete Transaction";

      confirmModalRef.result.then((result) => {

        this.spinnerService.start();
        this.userService.deleteNewTradeEditId(deleteData).subscribe(res => {

          if (res.data.undoGoals.length) {
            this.toastService.show("Records Deleted Successfully !", { classname: "bg-success text-light", delay: 5000 });
            this.getTradeFilteredData(this.searchQuery1);
          }

          if (res.data.errorGoals.length) {
            this.toastService.show(`Invalid Time To Goal : ${res.data.errorGoals}`, { classname: "bg-danger text-light", delay: 8000 });
          }

          this.spinnerService.stop();
        },
          error => {
            this.spinnerService.stop();
          }
        );
      },
        (reason) => {
        }
      );
    } else {
      this.toastService.show("Please Select Some Records For Delete", {
        classname: "bg-danger text-light"
      });
    }
  }


  /**
   * @function generateTradeTransactions
   * @description generate trade transanction for selected trade records
   */
  generateTradeTransactions() {
    // const selectedTrade = this.getSelectedRecordsForGenerateTrans();
    console.log("Using old techinique");
    const generateData = {
      editGoalList: this.selectedEditIds,
      filterData: {
        query: this.filterQuery1,
        limit: this.userCount1,
        skip: 0,
        broker: this.selectedNewTradeBrokersList
      }
    };

    if (this.selectedEditIds.length || this.selectedAll) {

      const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
      confirmModalRef.componentInstance.title = "Confirm Dialog";
      confirmModalRef.componentInstance.body = "Are You Sure To Generate Transaction";

      confirmModalRef.result.then((result) => {

        this.spinnerService.start();
        this.userService.generateTradeTransactions(generateData).subscribe(res => {

          if (!res.length) {
            this.toastService.show(`Invalid Time To Goal : ${res.data.errorGoals}`, { classname: "bg-danger text-light", delay: 8000 });
          }

          if (res.length) {
            this.toastService.show("Transaction Generated Successfully !", { classname: "bg-success text-light", delay: 5000 });

            this.generatedExcelData = res;
            this.generatedExcelData = this.generatedExcelData.filter((generatedDTradeData) => {
              if (generatedDTradeData.brokerName.toLowerCase() == 'edelweiss' || generatedDTradeData.brokerName.toLowerCase() == 'lm securities') {
                return generatedDTradeData;
              }
            })

            if (this.generatedExcelData.length) {
              this.downloadReport();
            } else {
              this.toastService.show("Edelweiss and LM Securities Broker Not Found For Downloading Report ", { classname: "bg-danger text-light", delay: 5000 });
            }

          }

          this.spinnerService.stop();
          this.getNewTradeRecords();
          this.getPendingTradeRecords();
        },
          error => {
            this.spinnerService.stop();
          }
        );

      },
        (reason) => {
        }
      );

    } else {
      this.toastService.show("Please Select Some Records For Generate Transaction", { classname: "bg-danger text-light" });
    }

  }


  /**
   * @function getSelectedDataForRecordTrans
   * @description return "edelweiss" and "lm securities" trades for update transanction
   */
  getSelectedDataForRecordTrans(): Array<any> {
    return this.pendingTradeData.filter((item) => {
      if ((item.brokerName.toLowerCase() == "edelweiss" || item.brokerName.toLowerCase() == "lm securities") && item.selected2 == true) {
        item.actualPrice = item.netPrice;
        item.actualQuantity = item.quantity;
        return item;
      }
    })
  }

  /**
   * @function recordTransactions
   * @description record selected trade transanction records
   */
  recordTransactions() {
    let selectedTrade = this.getSelectedDataForRecordTrans();

    if (selectedTrade.length) {
      this.spinnerService.start();
      this.userService.recordTransanctions(selectedTrade).subscribe((item) => {
        this.toastService.show("Quantity and Price Updated Successfully !", { classname: "bg-success text-light" });
        this.isEditable = false;
        this.spinnerService.stop();
        this.getPendingTradeRecords();
      })
    }
    else {
      this.toastService.show("Please Select Valid trade, Only #Edleweiss or #LM Securities Broker Allowed !", { classname: "bg-danger text-light" });
    }
  }

  /**
   * @function openTransactionFilterModal
   * @description open modal with multiple condition filter
   */
  openNewTradeFilterModal() {

    const newTradeModalRef = this.ngmodalService.open(NewTradeFilterModalComponent, this.filterModalOptions)
    newTradeModalRef.componentInstance.selectedBrokers = this.selectedNewTradeBrokersList;

    newTradeModalRef.componentInstance.userOptionData.subscribe(response => {

      this.p1 = 1;

      this.searchQuery1 = {
        filterData: {
          limit: this.rowsOnPage1,
          skip: this.p1,
          broker: response.selectedBrokers,
          query: this.filterQuery1
        }
      }

    });

    newTradeModalRef.result.then((result) => {
      this.getTradeFilteredData(this.searchQuery1);
    },
      (reason) => {
        this.getNewTradeRecords();
      }
    );

  }

  /**
   * @function openTransactionFilterModal
   * @description open modal with multiple condition filter
   */
  openPendingTradeFilterModal() {

    const pendingTradeModalRef = this.ngmodalService.open(PendingTradeFilterModalComponent, this.filterModalOptions)

    pendingTradeModalRef.componentInstance.selectedBrokers = this.selectedPendingTradeBrokersList;

    pendingTradeModalRef.componentInstance.userOptionData.subscribe(response => {

      this.p2 = 1;

      this.searchQuery2 = {
        limit: this.rowsOnPage2,
        skip: this.p2,
        broker: response.selectedBrokers,
        query: this.filterQuery2
      }

    });

    pendingTradeModalRef.result.then((result) => {
      this.getPendingTradeFilteredData(this.searchQuery2);
    },
      (reason) => {
        this.getPendingTradeRecords();
      }
    );

  }

  /**
   * @description set search query to behaviour subject on keyup event
   * @param event 
   */
  onNewTradeSearch(event) {
    this.userService.setTradeSearchData(event.target.value);
  }

  /**
   * @function onPendingTradeSearch
   * @description set search query to behaviour subject on keyup event
   * @param event 
   */
  onPendingTradeSearch(event) {
    this.userService.setPendingTradeSearchData(event.target.value);
  }

  /**
   * @function onNewTradeRowSearch
   * @description set rows count to subject on keyup event
   * @param event 
   */
  onNewTradeRowSearch(event) {
    this.userService.setNewTradeRowsData(event.target.value);
  }

  /**
   * @function onPendingTradeRowSearch
   * @description set rows count to subject on keyup event
   * @param event 
   */
  onPendingTradeRowSearch(event) {
    this.userService.setPendingTradeRowsData(event.target.value);
  }

  /**
    * @function getTradeFilteredData
    * @description Return Data depends upon filter option
    * @param obj 
    */
  getTradeFilteredData(obj) {
    this.userService.getTradeFilteredData(obj).subscribe((res) => {
      if (res) {
        this.newTradeData = res && res.args && res.args.data ? res.args.data : [];
        this.userCount1 = res && res.args && res.args.count ? res.args.count : 0;
        this.totalPagesCount1 = res["args"]['total_pages'];
        this.prePage1 = res["args"]['pre_page'];
        this.nextPage1 = res["args"]['next_page'];

        for (let i = 0; i < this.newTradeData.length; i++) {
          this.selectedEditIds.includes(this.newTradeData[i].editGoalId) || this.selectedAll
            ?
            this.newTradeData[i].selected = true
            :
            null;
        }

      }

      if (!this.newTradeData.length) {
        this.toastService.show("No New Trade Records !", { classname: "bg-danger text-light" });
      }
    })

    this.userService.setNewTradeEmpty(false);
  }


  /**
 * @function getPendingTradeFilteredData
 * @description Return Data depends upon filter option
 * @param obj 
 */
  getPendingTradeFilteredData(obj) {
    this.userService.getPendingTradeFilteredData(obj).subscribe((res) => {
      if (res) {
        this.pendingTradeData = res && res.pendingTrades && res.pendingTrades.data ? res.pendingTrades.data : [];
        this.userCount2 = res["pendingTrades"]["count"];
        this.totalPagesCount2 = res["pendingTrades"]['total_pages'];
        this.prePage2 = res["pendingTrades"]['pre_page'];
        this.nextPage2 = res["pendingTrades"]['next_page'];

        for (let i = 0; i < this.pendingTradeData.length; i++) {
          this.selectedPendingEditIds.includes(this.pendingTradeData[i].editGoalId) || this.selectedAll2
            ?
            this.pendingTradeData[i].selected2 = true
            :
            null;
        }

      }

      if (!this.pendingTradeData.length) {
        this.toastService.show("No Pending Trade Records !", { classname: "bg-danger text-light" });
      }
    })

    this.userService.setNewPendingTradeEmpty(false);
  }


  /**
 * @function downloadReport
 * @description download report in excel
 */
  downloadReport() {
    let report = [...this.generatedExcelData];
    let reportArr = [];
    report.forEach(element => {
      reportArr.push({
        tradeId: "",
        market: "NSE",
        series: "EQ",
        symbol: element.symbol ? element.symbol : "-",
        originalPrice: element.netPrice ? element.netPrice : "-",
        OriginalQunatity: element.quantity ? element.quantity : "-",
        orderType: "LIMIT",
        tradeType: "NRML",
        orderValidity: "DAY",
        brokerName: element.brokerName ? element.brokerName : "-",
        tradeAccount: element.brokerId ? element.brokerId : "-",
        editId: element.editGoalId ? element.editGoalId : "-",
        actualPrice: "",
        actualQuantity: "",
      });
    });

    this.excelService.exportAsExcelFile(
      reportArr,
      new Date().toISOString().slice(0, 10) + "-generated-trade-list"
    );
  }

}