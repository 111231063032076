import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginAuthGuard } from './auth/login-auth.guard';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WatchboardComponent } from './dashboard/watchboard/watchboard.component';
import { LeadsComponent } from './dashboard/leads/leads.component';
import { CashManagementComponent } from './dashboard/cash-management/cash-management.component';
import { TradeTerminalComponent } from './dashboard/trade-terminal/trade-terminal.component';
import { BasketAllocationsComponent } from './dashboard/basket-allocations/basket-allocations.component';
import { AutoPushNotificationComponent } from './dashboard/auto-push-notification/auto-push-notification.component';
import { UserManagementComponent } from './dashboard/user-management/user-management.component';
import { ChargesComponent } from './dashboard/charges/charges.component';
import { ReportsComponent } from './dashboard/reports/reports.component';
import { ClientActivationReportComponent } from './dashboard/reports/client-activation-report/client-activation-report.component';
import { TradingDetailsComponent } from './dashboard/reports/trading-details/trading-details.component';
import { AumReportComponent } from './dashboard/reports/aum-report/aum-report.component';
import { GoalCountReportComponent } from './dashboard/reports/goal-count-report/goal-count-report.component';
import { CreateNewUserComponent } from './dashboard/user-management/create-new-user/create-new-user.component';
import { AdminListComponent } from './dashboard/user-management/admin-list/admin-list.component';
import { GoalsComponent } from './dashboard/reports/trading-details/goals/goals.component';
import { EditGoalComponent } from './dashboard/reports/trading-details/edit-goal/edit-goal.component';
import { TradesComponent } from './dashboard/reports/trading-details/trades/trades.component';
import { GoalManagementComponent } from './dashboard/goal-management/goal-management.component';
import { ProcessingEditGoalsComponent } from './dashboard/goal-management/processing-edit-goals/processing-edit-goals.component';
import { GoalDetailsComponent } from './dashboard/goal-management/goal-details/goal-details.component';
import { PaymentComponent } from './dashboard/payment/payment.component';
import { UpdatePaymentComponent } from './dashboard/update-payment/update-payment.component';
import { DiscountComponent } from './dashboard/discount/discount.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: 'watchboard',
        component: WatchboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'leads/:id',
        component: LeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'cash-management',
        component: CashManagementComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'trade-terminal',
        component: TradeTerminalComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'new-trade-terminal',
        component: BasketAllocationsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reports',
        component: ReportsComponent,
        children: [
          {
            path: 'client-activation-report',
            component: ClientActivationReportComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'trading-details',
            component: TradingDetailsComponent,
            children: [
              {
                path: 'goals',
                component: GoalsComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'edit-goals',
                component: EditGoalComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'trades',
                component: TradesComponent,
                canActivate: [AuthGuard]
              },
              {
                path: '',
                redirectTo: 'goals',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'aum-report',
            component: AumReportComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'goal-count-report',
            component: GoalCountReportComponent,
            canActivate: [AuthGuard]
          },
          {
            path: '',
            redirectTo: 'client-activation-report',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'goal-management',
        component: GoalManagementComponent,
        children: [
          {
            path: 'goal-details',
            component: GoalDetailsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'processing-edit-goals',
            component: ProcessingEditGoalsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: '',
            redirectTo: 'goal-details',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'user-management',
        component: UserManagementComponent,
        children: [
          {
            path: 'admin-list',
            component: AdminListComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'create-new-user',
            component: CreateNewUserComponent,
            canActivate: [AuthGuard]
          },
          {
            path: '',
            redirectTo: 'admin-list',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'charges',
        component: ChargesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'auto-push-notification',
        component: AutoPushNotificationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'payment-overview',
        component: PaymentComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'update-payment',
        component: UpdatePaymentComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'coupon',
        component: DiscountComponent,
        canActivate: [AuthGuard]
      },
      {
        path: '',
        redirectTo: 'watchboard',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }