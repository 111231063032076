import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserService } from 'src/app/services/user.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WatchboardService } from '../../../services/watchboard/watchboard.service';
@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {

  @Input() filterSearch: any;
  activeTab: number = 1;

  // this data come from watchboard page
  @Input() selectedIds: any[];
  @Input() isAllSelected: boolean;
  @Input() query: any;
  @Input() count: number;
  @Input() broker = [];
  @Input() status = [];
  @Input() templates = [];
  @Input() sendObject: boolean = false;

  sendQuery: any;
  notificationType = "simple";


  emailOpen: boolean;
  blogNotfiction: boolean;
  showBlogDetails: boolean;
  showVideoDetails: boolean;

  // form 
  notificationForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private watchboardService: WatchboardService,
    private toastService: ToastService,
    private fb: FormBuilder
  ) { }

  /**
   * @function ngOnInit
   * @description Set the header title
   */
  ngOnInit() {
    this.initiForm();
  }

  initiForm() {
    this.notificationForm = this.fb.group({
      message: ["", Validators.compose([Validators.required])],
      subject: [""],
      bigPicture: [""],
      blogHeader: [""],
      communicationNotification: [this.notificationType],
      blogUrl: [""],
      video_id: [""],
      templateId: [""],
    })
  }

  selectNav(activeNav: number) {
    this.activeTab = activeNav;
  }

  /**
   * @function changeNotification
   * @description use for toggeling into blog,video,simple notification
   * @param event 
   */
  changeNotification(event: any) {
    this.notificationType = event.target.value;

    switch (event.target.value) {
      case "blog":
        this.showBlogDetails = true;
        this.showVideoDetails = false;
        break;

      case "video":
        this.showBlogDetails = false;
        this.showVideoDetails = true;
        break;

      default:
        this.showBlogDetails = false;
        this.showVideoDetails = false;
        break;
    }
  }

  sendNotification() {
    switch (this.activeTab) {
      case 2:
        this.smsBody();
        break;
      case 3:
        this.mailBody();
        break;
      default:
        this.notification();
        break;
    }
    console.log(this.sendQuery);
  }

  notification() {
    const notifiyT = this.notificationType;

    this.sendQuery = {
      filter: this.filterSearch,
      message: this.notificationForm.get('message').value,
      title: this.notificationForm.get('blogHeader').value,
      communicationNotification: this.notificationForm.get('communicationNotification').value,
      body: this.notificationForm.get('message').value,
      image: null,
      data: {
        title: this.notificationForm.get('blogHeader').value,
        action: this.notificationForm.get('communicationNotification').value,
        body: this.notificationForm.get('message').value,
        image: null,
        link: null,
        video_id: null,
      }
    };

    if (notifiyT == "blog") {
      const blogImg = this.notificationForm.get('bigPicture').value;
      const link = this.notificationForm.get('blogUrl').value;
      this.sendQuery.image = blogImg;
      this.sendQuery.data.image = blogImg;
      this.sendQuery.data.link = link;
    }

    if (notifiyT == "video") {
      const video_id = this.notificationForm.get('video_id').value;
      const img = `https://img.youtube.com/vi/${video_id}/mqdefault.jpg`;
      const link = `https://www.youtube.com/watch?v=${video_id}&feature=youtu.be`;
      this.sendQuery.image = img;
      this.sendQuery.data.image = img;
      this.sendQuery.data.link = link;
      this.sendQuery.data.video_id = video_id;
    }
    if (!this.sendObject) {
      this.watchboardService.sendFirebaseNotification(this.sendQuery).subscribe((res) => {
        console.log(res);
        this.activeModal.close('Ok click');
      });
    } else {
      this.activeModal.close(this.sendQuery);
    }
  }

  smsBody() {
    this.sendQuery = {
      header: "TAVAGA",
      filter: this.filterSearch,
      message: this.notificationForm.get('message').value
    }
    if (!this.sendObject) {
      this.watchboardService.sendSms(this.sendQuery).subscribe((res) => {
        console.log(res);
        this.activeModal.close('Ok click');
      });
    } else {
      this.activeModal.close(this.sendQuery);

    }
  }

  mailBody() {
    this.sendQuery = {
      subject: this.notificationForm.get('subject').value,
      message: this.notificationForm.get('message').value,
      filter: this.filterSearch,
    }
    if (!this.sendObject) {

      this.watchboardService.sendMail(this.sendQuery).subscribe((res) => {
        console.log(res);
        this.activeModal.close('Ok click');
      });
    } else {
      this.activeModal.close(this.sendQuery);

    }
  }

}
