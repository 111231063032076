import {
  Component,
  OnInit
} from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { ExcelService } from "src/app/services/excel.service";
import { PageTitleService } from "src/app/services/page-title.service";
import { Router } from "@angular/router";
import { SpinnerService } from "src/app/services/spinner.service";
import { ToastService } from "src/app/core/shared/services/toast.service";
import { CashFilterModalComponent } from "./cash-filter-modal/cash-filter-modal.component";
import { TransactionFilterModalComponent } from "./transaction-filter-modal/transaction-filter-modal.component";
import { ConfirmModalComponent } from 'src/app/core/dialogs/confirm-modal/confirm-modal.component';
import { EtfPriceUpdateModalComponent } from './etf-price-update-modal/etf-price-update-modal.component';
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';
import {
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';

@Component({
  selector: "app-cash-management",
  templateUrl: "./cash-management.component.html",
  styleUrls: ["./cash-management.component.scss"]
})
export class CashManagementComponent implements OnInit {

  p: number = 1;
  userCount: number;

  p2: number = 1;
  userCount2: number;

  //for toggling of table data <td> for generate transction and refresh pending API
  isGenerated: boolean = false;

  // checkbox related
  selectedAll: any;
  selected: false;

  selectedAll2: any;
  selected2: false;

  filterQuery1 = "";
  searchQuery1: any;
  rowsOnPage1 = 10;
  totalPagesCount1: number;
  prePage1: any;
  nextPage1: any;

  filterQuery2 = "";
  searchQuery2: any;
  rowsOnPage2 = 10;
  sortOrder = "desc";
  sortBy = "id";
  totalPagesCount2: number;
  prePage2: any;
  nextPage2: any;

  filterQuery3 = "";
  rowsOnPage3 = 6;

  newCashData = [];
  etfData = [];
  cashTransanctionData = [];

  // for selected edit ids
  selectedEditIds = [];

  // for selected edit ids in pending records
  selectedPendingEditIds = [];

  // for generate all cash transaction
  newCashAllData: any[];

  // for record all cash transaction
  cashTransactionAllData: any[];

  //row selection related
  isSelectedRow1: number;
  isSelectedRow2: number;
  isSelectedRow3: number;

  confirmModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    size: 'sm',
    centered: true,
  };

  filterModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    size: 'lg',
    centered: true,
  };

  // eom script related
  maintainanceTypeList = [
    { maintenancetype: "1", EOM: 5, value: "false" },
    { maintenancetype: "2", EOM: 10, value: "false" },
    { maintenancetype: "3", EOM: 15, value: "false" },
    { maintenancetype: "4", EOM: 20, value: "false" }
  ];

  maintenanceTypeId: string;
  EombuttonDisabled: any;

  //cash filter related
  selectedCashBrokersList = [];
  selectedCashMinAmount = 0;
  selectedCashMaxAmount = 0;

  //transaction filter related
  selectedTransBrokersList = [];
  selectedTransMinAmount = 0;
  selectedTransMaxAmount = 0;

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private router: Router,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private ngmodalService: NgbModal
  ) { }

  /**
   * @function ngOnInit
   * @description Set header title
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.pageTitleService.setTitle("Cash Management");

    this.searchQuery1 = {
      limit: this.rowsOnPage1,
      skip: this.p,
      broker: [],
      minAmount: this.selectedCashMinAmount,
      maxAmount: this.selectedCashMaxAmount,
      query: this.filterQuery1
    }

    this.searchQuery2 = {
      limit: this.rowsOnPage2,
      skip: this.p2,
      broker: [],
      minAmount: this.selectedTransMinAmount,
      maxAmount: this.selectedTransMaxAmount,
      query: this.filterQuery2
    }

    this.userService.cashSearchData.pipe(debounceTime(1000),
      distinctUntilChanged()).subscribe((res) => {
        this.filterQuery1 = res;
        this.searchQuery1.query = this.filterQuery1;
        this.getCashFilteredData(this.searchQuery1);
      })

    this.userService.newCashRowsData.pipe(debounceTime(1000),
      distinctUntilChanged()).subscribe((res) => {
        this.rowsOnPage1 = Number(res);
        this.searchQuery1.limit = this.rowsOnPage1;
        this.getCashFilteredData(this.searchQuery1);
      })

    this.userService.pendingCashSearchData.pipe(debounceTime(1000),
      distinctUntilChanged()).subscribe((res) => {
        this.filterQuery2 = res;
        this.searchQuery2.query = this.filterQuery2;
        this.getPendingCashFilteredData(this.searchQuery2);
      })

    this.userService.pendingCashRowsData.pipe(debounceTime(1000),
      distinctUntilChanged()).subscribe((res) => {
        this.rowsOnPage2 = Number(res);
        this.searchQuery2.limit = this.rowsOnPage2;
        this.getPendingCashFilteredData(this.searchQuery2);
      })

    this.getCashFilteredData(this.searchQuery1);
    this.getEtfDetails();
  }

  /**
   * @function getNewCashRecords
   * @description for get new cash records in cash-management page
   */
  getNewCashRecords() {
    this.selectedEditIds = [];
    this.selectedAll = false;

    this.filterQuery1 = "";
    this.userService.setNewCashEmpty(true);
    this.selectedCashBrokersList = [];
    this.selectedCashMinAmount = 0;
    this.selectedCashMaxAmount = 0;
    this.rowsOnPage1 = 10;
    this.p = 1;
    this.searchQuery1.limit = this.rowsOnPage1;
    this.searchQuery1.skip = this.p;
    this.searchQuery1.broker = [];
    this.searchQuery1.minAmount = 0;
    this.searchQuery1.maxAmount = 0;
    this.searchQuery1.query = this.filterQuery1;

    this.userService.setCashSearchData(this.filterQuery1);
    this.getCashFilteredData(this.searchQuery1);
  }

  /**
   * @function getPendingRecords
   * @description for get new pending cash records in cash-management page
   */
  getPendingRecords() {
    this.selectedPendingEditIds = [];
    this.selectedAll2 = false;

    this.filterQuery2 = "";
    this.userService.setNewPendingCashEmpty(true);
    this.selectedTransBrokersList = [];
    this.selectedTransMinAmount = 0;
    this.selectedTransMaxAmount = 0;
    this.rowsOnPage2 = 10;
    this.p2 = 1;
    this.searchQuery2.limit = this.rowsOnPage2;
    this.searchQuery2.skip = this.p2;
    this.searchQuery2.broker = [];
    this.searchQuery2.minAmount = 0;
    this.searchQuery2.maxAmount = 0;
    this.searchQuery2.query = this.filterQuery2;

    this.userService.setPendingCashSearchData(this.filterQuery2);
    this.getPendingCashFilteredData(this.searchQuery2);
  }

  /**
   * @function getEtfDetails
   * @description for geting ETF records in cash-management page
   */
  getEtfDetails() {
    this.spinnerService.start();
    this.userService.getEtfDetails().subscribe(res => {
      this.etfData = res["currentEtfDetails"];
      this.spinnerService.stop();
    });
  }

  /**
   * @function selectAll
   * @description for checked and unchecked all checkboxes in cash table in cash-management page
   */
  selectAll() {
    this.selectedEditIds = [];

    this.newCashData.map((item) => {
      item.selected = this.selectedAll;
    })

  }

  /**
   * @function checkIfAllSelected
   * @description for checked and unchecked single checkboxe in cash table in cash-management page
   */
  checkIfAllSelected(value) {
    let i = this.selectedEditIds.indexOf(value);
    if (i != -1) {
      this.selectedEditIds.splice(i, 1);
    } else {
      this.selectedEditIds.push(value);
    }

    this.selectedAll = this.newCashData.every((item: any) => {
      return item.selected == true;
    });

  }

  /**
   * @function selectAll2
   * @description for checked and unchecked all checkboxes in cash transanction table in cash-management page
   */
  selectAll2() {
    this.selectedPendingEditIds = [];

    this.cashTransanctionData.map((item) => {
      item.selected2 = this.selectedAll2;
    })

  }

  /**
   * @function checkIfAllSelected2
   * @description for checked and unchecked single checkboxe in cash table in cash-management page
   */
  checkIfAllSelected2(value) {
    let i = this.selectedPendingEditIds.indexOf(value);
    if (i != -1) {
      this.selectedPendingEditIds.splice(i, 1);
    } else {
      this.selectedPendingEditIds.push(value);
    }

    this.selectedAll2 = this.cashTransanctionData.every((item: any) => {
      return item.selected2 == true;
    });

  }

  /**
   * @function onEomCheckboxClick
   * @description this function check checkbox value and select one checkbox at-time button disabled and enabled too
   */
  onEomCheckboxClick(value, event) {
    this.maintainanceTypeList.forEach(elem => {
      if (elem.maintenancetype === value.maintenancetype) {
        this.maintenanceTypeId = elem.maintenancetype;
        this.EombuttonDisabled = event.target.checked;
      }
    });
  }

  /**
   * @function runEOM
   * @description run EOM(End Of Month) script
   */
  runEOM() {
    this.spinnerService.start();
    this.userService.runEomScript(this.maintenanceTypeId).subscribe(res => {
      if (res["endofmonth"].status === 200) {
        this.toastService.show("EOM Script Successfully Run !", { classname: "bg-success text-light" });
        this.spinnerService.stop();
      }
      else {
        let msg = res["endofmonth"] ? res["endofmonth"] : "Something went wrong!!";
        this.toastService.show(msg, { classname: "bg-danger text-light" });
        this.spinnerService.stop();
      }
    },
      err => {
        this.toastService.show("Invalid Time To Goal !", { classname: "bg-danger text-light" });
        this.spinnerService.stop();
      }
    );
  }

  /**
   * @function onSelectRow1
   * @description for dynamic class added to row after selecting row
   * @param index1
   */
  onSelectRow1(index1) {
    this.isSelectedRow1 = index1;
  }

  /**
   * @function onSelectRow2
   * @description for dynamic class added to row after selecting row
   * @param index2
   */
  onSelectRow2(index2) {
    this.isSelectedRow2 = index2;
  }

  /**
   * @function onSelectRow3
   * @description for dynamic class added to row after selecting row
   * @param index3
   */
  onSelectRow3(index3) {
    this.isSelectedRow3 = index3;
  }

  /**
   * @function goToLeadsPage
   * @description for navigate to leads page with tavaga-id
   * @param tavagaid
   */
  goToLeadsPage(tavagaid) {
    this.userService.setRouteData('/dashboard/cash-management');
    this.router.navigate(["/dashboard/leads", tavagaid]);
  }

  /**
   * @function getSelectedRecords
   * @description returns only editGoalId of checked records in cash table
   */
  // getSelectedRecords(): Array<any> {
  //   return this.newCashData
  //     .filter(cashData => {
  //       return cashData.selected == true;
  //     })
  //     .map(data => data.editGoalId);
  // }

  /**
   * @function getSelectedCashRecords
   * @description returns only id of checked records in cash transnction table
   */
  // getSelectedCashRecords(): Array<any> {
  //   return this.cashTransanctionData
  //     .filter(cashData => {
  //       return cashData.selected2 == true;
  //     })
  //     .map(data => data.id);
  // }

  /**
   * @function deleteNewCashRecords
   * @description delete one or multiple selected cash records
   */
  deleteNewCashRecords() {
    const deleteData = {
      editGoalList: this.selectedEditIds,
      filterData: {
        query: this.filterQuery1,
        limit: this.userCount,
        skip: 0,
        minAmount: this.selectedCashMinAmount,
        maxAmount: this.selectedCashMaxAmount,
        broker: this.selectedCashBrokersList
      }
    };

    this.searchQuery1 = {
      limit: this.rowsOnPage1,
      skip: this.p,
      broker: this.selectedCashBrokersList,
      minAmount: this.selectedCashMinAmount,
      maxAmount: this.selectedCashMaxAmount,
      query: this.filterQuery1
    }

    if (this.selectedEditIds.length || this.selectedAll) {

      const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
      confirmModalRef.componentInstance.title = "Confirm Dialog";
      confirmModalRef.componentInstance.body = "Are You Sure To Delete Transaction";

      confirmModalRef.result.then((result) => {

        this.spinnerService.start();
        this.userService.editGoalDeletes(deleteData).subscribe(res => {

          if (res.editGoalList.length) {
            this.toastService.show("Records Deleted Successfully !", { classname: "bg-success text-light", delay: 5000 });
            this.getCashFilteredData(this.searchQuery1);
          }

          if (!res.editGoalList.length) {
            this.toastService.show(`No Goals Found`, { classname: "bg-danger text-light", delay: 8000 });
          }

          this.spinnerService.stop();
        }
        );
      },
        (reason) => {
        }
      );
    } else {
      this.toastService.show("Please Select Some Records For Delete", { classname: "bg-danger text-light" });
    }
  }

  /**
   * @function generateTransactions
   * @description generate transanction for selected records
   */
  generateTransactions() {
    // const selectedCashData = this.getSelectedRecords();

    const generateData = {
      editGoalList: this.selectedEditIds,
      filterData: {
        query: this.filterQuery1,
        limit: this.userCount,
        skip: 0,
        minAmount: this.selectedCashMinAmount,
        maxAmount: this.selectedCashMaxAmount,
        broker: this.selectedCashBrokersList
      }
    };

    if (this.selectedEditIds.length || this.selectedAll) {

      const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
      confirmModalRef.componentInstance.title = "Confirm Dialog";
      confirmModalRef.componentInstance.body = "Are You Sure To Generate Transaction";

      confirmModalRef.result.then((result) => {

        this.spinnerService.start();
        this.userService.generateTransactions(generateData).subscribe(res => {

          if (res.data.editGoalArr.length) {
            this.toastService.show("Transaction Generated Successfully !", { classname: "bg-success text-light", delay: 5000 });
            this.getNewCashRecords();
            this.getPendingRecords();
          }

          if (res.data.errorGoals.length) {
            this.toastService.show(`Invalid Time To Goal : ${res.data.errorGoals}`, { classname: "bg-danger text-light", delay: 8000 });
          }

          if (!res.data.editGoalArr.length && !res.data.errorGoals.length) {
            this.toastService.show(`No Goals Found`, { classname: "bg-danger text-light", delay: 8000 });
          }

          this.spinnerService.stop();
        },
          error => {
            this.spinnerService.stop();
          }
        );

      },
        (reason) => {
        }
      );

    } else {
      this.toastService.show("Please Select Some Records For Generate Transaction", { classname: "bg-danger text-light" });
    }
  }

  /**
 * @function recordCashTransactions
 * @description record selected cash transanction records
 */
  recordCashTransactions() {
    // const selectedCashData = this.getSelectedCashRecords();

    const recordData = {
      editGoalList: this.selectedPendingEditIds,
      filterData: {
        query: this.filterQuery2,
        limit: this.userCount2,
        skip: 0,
        minAmount: this.selectedTransMinAmount,
        maxAmount: this.selectedTransMaxAmount,
        broker: this.selectedTransBrokersList
      }
    };

    if (this.selectedPendingEditIds.length > 0 || this.selectedAll2) {

      const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
      confirmModalRef.componentInstance.title = "Confirm Dialog";
      confirmModalRef.componentInstance.body = "Are You Sure To Record Transaction";

      confirmModalRef.result.then((result) => {

        this.spinnerService.start();
        this.userService.recordCashTransactions(recordData).subscribe(res => {

          if (res.editGoalList.length > 0) {
            this.toastService.show("Transaction Recorded Successfully !", { classname: "bg-success text-light", delay: 5000 });
            this.getPendingRecords();
          }

          this.spinnerService.stop();
        },
          error => {
            this.spinnerService.stop();
          });
      },
        (reason) => {
        }
      );

    } else {
      this.toastService.show("Please Select Some Records For Record Transaction", { classname: "bg-danger text-light" });
    }

  }


  /**
   * @function deletePendingCashRecords
   * @description delete one or multiple selected pending cash records
   */
  deletePendingCashRecords() {
    const deleteData = {
      editGoalList: this.selectedPendingEditIds,
      filterData: {
        query: this.filterQuery2,
        limit: this.userCount2,
        skip: 0,
        minAmount: this.selectedTransMinAmount,
        maxAmount: this.selectedTransMaxAmount,
        broker: this.selectedTransBrokersList
      }
    };

    this.searchQuery2 = {
      limit: this.rowsOnPage2,
      skip: this.p2,
      broker: this.selectedTransBrokersList,
      minAmount: this.selectedTransMinAmount,
      maxAmount: this.selectedTransMaxAmount,
      query: this.filterQuery2
    }

    if (this.selectedPendingEditIds.length > 0 || this.selectedAll2) {

      const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
      confirmModalRef.componentInstance.title = "Confirm Dialog";
      confirmModalRef.componentInstance.body = "Are You Sure To Delete Transaction";

      confirmModalRef.result.then((result) => {

        this.spinnerService.start();
        this.userService.deleteCashTransactions(deleteData).subscribe(res => {
          this.toastService.show("Records Deleted Successfully !", { classname: "bg-success text-light" });
          this.spinnerService.stop();

          this.getPendingCashFilteredData(this.searchQuery2);
        });

      },
        (reason) => {
        }
      );

    } else {
      this.toastService.show("Please Select Some Records For Delete", {
        classname: "bg-danger text-light"
      });
    }
  }

  /**
   * @function onPageChange
   * @description on page change event fire,return current page number
   * @param activePage 
   */
  onPageChange1(activePage1) {
    document.documentElement.scrollTop = 0;
    this.p = activePage1
    this.searchQuery1.skip = this.p;
    this.searchQuery1.limit = this.rowsOnPage1;

    this.getCashFilteredData(this.searchQuery1);
  }

  /**
 * @function goToFirstPage
 * @description go to the first page
 */
  goToFirstPage1() {
    this.p = 1;
    this.searchQuery1.skip = this.p;

    this.getCashFilteredData(this.searchQuery1);
  }

  /**
   * @function goToLastPage
   * @description go to the last page
   */
  goToLastPage1() {
    this.p = this.totalPagesCount1;
    this.searchQuery1.skip = this.p;

    this.getCashFilteredData(this.searchQuery1);
  }

  /**
 * @function onPageChange2
 * @description on page change event fire,return current page number
 * @param activePage 
 */
  onPageChange2(activePage2) {
    this.p2 = activePage2
    this.searchQuery2.skip = this.p2;
    this.searchQuery2.limit = this.rowsOnPage2;

    this.getPendingCashFilteredData(this.searchQuery2);
  }

  /**
* @function goToFirstPage2
* @description go to the first page
*/
  goToFirstPage2() {
    this.p2 = 1;
    this.searchQuery2.skip = this.p2;

    this.getPendingCashFilteredData(this.searchQuery2);
  }

  /**
   * @function goToLastPage2
   * @description go to the last page
   */
  goToLastPage2() {
    this.p2 = this.totalPagesCount2;
    this.searchQuery2.skip = this.p2;

    this.getPendingCashFilteredData(this.searchQuery2);
  }

  /**
   * @function openCashFilterModal
   * @description open modal with multiple condition filter
   */
  openCashFilterModal() {

    const cashModalRef = this.ngmodalService.open(CashFilterModalComponent, this.filterModalOptions)

    cashModalRef.componentInstance.selectedBrokers = this.selectedCashBrokersList;
    cashModalRef.componentInstance.minAmount = this.selectedCashMinAmount;
    cashModalRef.componentInstance.maxAmount = this.selectedCashMaxAmount;

    cashModalRef.componentInstance.userOptionData.subscribe(response => {
      this.selectedCashMinAmount = response.minAmount;
      this.selectedCashMaxAmount = response.maxAmount;

      this.p = 1;

      this.searchQuery1 = {
        limit: this.rowsOnPage1,
        skip: this.p,
        broker: response.selectedBrokers,
        minAmount: this.selectedCashMinAmount,
        maxAmount: this.selectedCashMaxAmount,
        query: this.filterQuery1
      }

    });

    cashModalRef.result.then((result) => {
      this.getCashFilteredData(this.searchQuery1);
    }, (reason) => {
      this.getNewCashRecords();
    });

    // if (!this.newCashData.length) {
    //   this.toastService.show("No New Cash Records For Filter !", { classname: "bg-danger text-light" });
    // }

  }

  /**
   * @function openTransactionFilterModal
   * @description open modal with multiple condition filter
   */
  openTransactionFilterModal() {

    const transactionModalRef = this.ngmodalService.open(TransactionFilterModalComponent, this.filterModalOptions)

    transactionModalRef.componentInstance.selectedBrokers = this.selectedTransBrokersList;
    transactionModalRef.componentInstance.minAmount = this.selectedTransMinAmount;
    transactionModalRef.componentInstance.maxAmount = this.selectedTransMaxAmount;

    transactionModalRef.componentInstance.userOptionData.subscribe(response => {
      this.selectedTransMinAmount = response.minAmount;
      this.selectedTransMaxAmount = response.maxAmount;

      this.p2 = 1;

      this.searchQuery2 = {
        limit: this.rowsOnPage2,
        skip: this.p2,
        broker: response.selectedBrokers,
        minAmount: this.selectedTransMinAmount,
        maxAmount: this.selectedTransMaxAmount,
        query: this.filterQuery2
      }

    });

    transactionModalRef.result.then((result) => {
      this.getPendingCashFilteredData(this.searchQuery2);
    }, (reason) => {
      this.getPendingRecords();
    });

    // if(!this.cashTransanctionData.length) {
    //   this.toastService.show("No Pending Cash Records For Filter !", { classname: "bg-danger text-light" });
    // }

  }

  /**
   * @function getCashFilteredData
   * @description Return Data depends upon filter option
   * @param obj 
   */
  getCashFilteredData(obj) {
    this.userService.getCashFilteredData(obj).subscribe((res) => {
      if (res) {
        this.newCashData = res["newcash"]["data"];
        this.userCount = res["newcash"]["count"];
        this.totalPagesCount1 = res["newcash"]['total_pages'];
        this.prePage1 = res["newcash"]['pre_page'];
        this.nextPage1 = res["newcash"]['next_page'];

        for (let i = 0; i < this.newCashData.length; i++) {
          this.selectedEditIds.includes(this.newCashData[i].editGoalId) || this.selectedAll
            ?
            this.newCashData[i].selected = true
            :
            null;
        }

      }

      if (!this.newCashData.length) {
        this.toastService.show("No New Cash Records !", { classname: "bg-danger text-light" });
      }
    })

    this.userService.setNewCashEmpty(false);
  }


  /**
   * @description set search query to behaviour subject on keyup event
   * @param event 
   */
  onGlobalSearch(event) {
    this.userService.setCashSearchData(event.target.value);
  }

  /**
   * @function onPendingGlobalSearch
   * @description set search query to behaviour subject on keyup event
   * @param event 
   */
  onPendingGlobalSearch(event) {
    this.userService.setPendingCashSearchData(event.target.value);
  }

  /**
   * @function onNewCashRowSearch
   * @description set rows count to subject on keyup event
   * @param event 
   */
  onNewCashRowSearch(event) {
    this.userService.setNewCashRowsData(event.target.value);
  }

  /**
   * @function onPendingCashRowSearch
   * @description set rows count to subject on keyup event
   * @param event 
   */
  onPendingCashRowSearch(event) {
    this.userService.setPendingCashRowsData(event.target.value);
  }

  /**
   * @function getPendingCashFilteredData
   * @description Return Data depends upon filter option
   * @param obj 
   */
  getPendingCashFilteredData(obj) {
    this.userService.getPendingCashFilteredData(obj).subscribe((res) => {
      if (res) {
        this.cashTransanctionData = res["data"];
        this.userCount2 = res["count"];
        this.totalPagesCount2 = res['total_pages'];
        this.prePage2 = res['pre_page'];
        this.nextPage2 = res['next_page'];

        for (let i = 0; i < this.cashTransanctionData.length; i++) {
          this.selectedPendingEditIds.includes(this.cashTransanctionData[i].editGoalId) || this.selectedAll2
            ?
            this.cashTransanctionData[i].selected2 = true
            :
            null;
        }

      }

      if (!this.cashTransanctionData.length) {
        this.toastService.show("No Pending Cash Records !", { classname: "bg-danger text-light" });
      }
    })

    this.userService.setNewPendingCashEmpty(false);
  }

  /**
   * @function openEtfPriceUpdateModal
   * @description open ETF price update modal
   */
  openEtfPriceUpdateModal() {
    const updateModalRef = this.ngmodalService.open(EtfPriceUpdateModalComponent, this.filterModalOptions);
    updateModalRef.componentInstance.etfData = this.etfData;

    updateModalRef.result.then((result) => {
      this.spinnerService.start();
      this.userService.updateEtfPrice(this.etfData).subscribe(res => {
        if (res["Currentetf"]["status"] === 200)
          this.toastService.show("ETF Price Updated Successfully !", { classname: "bg-success text-light", delay: 5000 });
        this.spinnerService.stop();
        this.getEtfDetails();
      },
        err => {
          this.toastService.show("Error Price Not Updated !", { classname: "bg-danger text-light", delay: 5000 });
          this.spinnerService.stop();
        }
      );
    },
      (reason) => {
        this.getEtfDetails();
      }
    );
  }

}
