import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef
} from "@angular/core";
import {
  Router,
  NavigationEnd
} from "@angular/router";
import { SpinnerService } from "./services/spinner.service";
import { UserService } from "./services/user.service";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  subscription: Subscription;
  title = "tavaga-client";
  showSpinner = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private changeRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));
  }

  ngAfterViewInit() {
    this.spinnerService.loading.subscribe(res => {
      this.showSpinner = res;
      this.changeRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
