import {
    Component,
    ViewChild,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    ParamMap,
    Router
} from '@angular/router';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { PageTitleService } from 'src/app/services/page-title.service';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';
import { ConfirmModalComponent } from 'src/app/core/dialogs/confirm-modal/confirm-modal.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CreatePaymentLinkModalComponent } from './create-payment-link-modal/create-payment-link-modal.component';
import { PaymentService } from 'src/app/services/payment/payment.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-leads',
    templateUrl: './leads.component.html',
    styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {

    @ViewChild('acc', { static: false }) acc;
    dropdownSettings: IDropdownSettings = {};
    selectedEOMGoals = [];
    eomGoals = [];
    latestPaymentLinkDt;

    // sub goal realated
    isShowSubGoal = false;

    userId: number;
    selectedUserData: any;
    pennyDropAccount: any;
    paymentData: any = [];
    upcomingPaymentData: any = [];

    mappings = {
        paymentStatusMap: {},
        kycStatusMap: {},
        deviceTypeMap: {},
        appStatusMap: {},
        tradeDayMap: {
            1: '5th of every month',
            2: '10th of every month',
            3: '15th of every month',
            4: '20th of every month',
        },
        riskLevelMap: {
            1: 'Conservative',
            2: 'Controlled',
            3: 'Balanced',
            4: 'Growth',
            5: 'Aggresive'
        }
    };
    customerGoalsData: any;
    subGoalData: any;

    filterQuery = "";
    rowsOnPage = 5;
    rowsOnPage3 = 5;

    updateModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: true,
        size: 'xl',
        centered: true,
    };

    lgModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: true,
        size: 'lg',
        centered: true,
    };

    planMap = {
        YEARLY: "Annual",
        HALFYEARLY: "Semi-Annual",
      };

    paymentPlans = [];

    confirmModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: true,
        size: 'sm',
        centered: true,
    };

    previousPagePath: string;
    generatingEOM: boolean = false;
    eomMsg: any = { success: "", error: "" };

    constructor(
        private pageTitleService: PageTitleService,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private ngmodalService: NgbModal,
        private paymentService: PaymentService
    ) { }

    /**
     * @function ngOnInit
     * @description set header title
     */
    ngOnInit() {
        document.documentElement.scrollTop = 0;
        this.pageTitleService.setTitle('User Details');

        this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
            this.userId = Number(params.get('id'));
        });

        this.userService.routeData.subscribe((res: string) => {
            this.previousPagePath = res;
        })

        this.getUserById(this.userId);

        this.getCustomerGoalsById(this.userId);

        this.getAllFrequency();

        this.getPaymentLinks();

        this.initPlugin();
    }

    getPaymentLinks(){
        this.paymentService.getPaymentLinks({userId: this.userId}).subscribe((res) => {
            const latest = _.orderBy(res, 'transactionAt','desc')[0];
            if(latest){
                this.latestPaymentLinkDt = latest.transactionAt
            }
        });
    }


    initPlugin() {
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'goalId',
            textField: 'goalName',
            itemsShowLimit: 3,
            allowSearchFilter: false,
            enableCheckAll: true
        };
    }
    /**
     * @function getUserById
     * @description get user details by id
     * @param userId
     */
    getUserById(userId) {
        this.userService.getUserById(userId).subscribe((res) => {
            this.mappings.appStatusMap = res.mappings.appStatusMap;
            this.mappings.kycStatusMap = res.mappings.kycStatusMap;
            this.mappings.paymentStatusMap = res.mappings.paymentStatusMap;
            this.mappings.deviceTypeMap = res.mappings.deviceTypeMap;

            this.selectedUserData = res.userData;
            this.selectedUserData.name = `${(res.userData.givenName || '').trim()} ${(res.userData.familyName || '').trim()}`;

            const pennyDrops = this.selectedUserData.pennyDropAccount;
            let pennyDropAccount = {};
            if(pennyDrops && pennyDrops.length){
                const activePennyDrop = pennyDrops.find(pd => pd.accountStatus.toUpperCase() === 'ACTIVE');
                pennyDropAccount = activePennyDrop || {};
            }
            this.pennyDropAccount = pennyDropAccount;

            if (this.selectedUserData.allowance && this.selectedUserData.allowance.length) {
                let payments = this.selectedUserData.allowance;
                payments = payments.map(al => {
                    const pf = al.paymentFrequency;
                    const pp = al.processingPayment;
                    const coupon = pp ? pp.coupon : null;
                    return {
                        orderId: al.orderId,
                        status: al.isExpired ? 'Expired' : 'Active',
                        plan: pf ? pf.frequency : null,
                        amount: pp ? Number(pp.amountInPaisa) / 100 : null,
                        paymentMode: pp ? pp.payType : null,
                        paymentDate: al.createdAt,
                        expiryDate: al.expiryDate,
                        coupon: coupon ? coupon.discountCode : null,
                        invoice: al.invoiceUrl
                    }
                });
                this.paymentData = payments;
            }
        });

        this.paymentService.getUpcomingPayments({userId: this.userId}).subscribe(res=>{
            const data = res.map(pp => {
                const coupon = pp.coupon;
                const pf= pp.paymentFrequency;
                return {
                    orderId: pp.orderId,
                    status: 'Paid (Upcoming)',
                    plan: pf ? pf.frequency : null,
                    amount: Number(pp.amountInPaisa) / 100,
                    paymentMode: pp ? pp.payType : null,
                    paymentDate: pp.transactionAt,
                    planEndDate: pp.planEndDate,
                    planStartDate: pp.planStartDate,
                    coupon: coupon ? coupon.discountCode : null
                }
            })
            this.upcomingPaymentData = data;
        })
    }

    /**
     * @function getCustomerGoalsById
     * @description get customer goals details by id
     * @param userId
     */
    getCustomerGoalsById(userId) {
        this.userService.getCustomerGoalsById(userId).subscribe((res) => {
            let eomGoals = [];
            this.customerGoalsData = this.dataOperation(res['goal']['goals']);
            this.customerGoalsData = this.customerGoalsData.filter((goal) => {
                return goal.goalStatus == "ACTIVE"
            });
            this.customerGoalsData && this.customerGoalsData.forEach((g) => {
                eomGoals.push({ goalId: g.id, goalName: g.name });
            });
            this.eomGoals = eomGoals;
        });

    }


    /**
     * @function dataOperation
     * @description set goalTypeName to each goal on the basis of their goalType
     * @param goalObj
     */
    dataOperation(goalObj) {

        goalObj.forEach(item => {
            switch (item.goalType) {
                case 0:
                    item.goalTypeName = "WEALTH_MAX";
                    break;
                case 1:
                    item.goalTypeName = "EMERGENCY_FUND";
                    break;
                case 2:
                    item.goalTypeName = "HOUSE";
                    break;
                case 3:
                    item.goalTypeName = "EDUCATION";
                    break;
                case 4:
                    item.goalTypeName = "CRICKET";
                    break;
                case 5:
                    item.goalTypeName = "FOOOTBALL";
                    break;
                case 6:
                    item.goalTypeName = "FOREIGN";
                    break;
                case 7:
                    item.goalTypeName = "SWAG";
                    break;
                case 8:
                    item.goalTypeName = "CAR";
                    break;
                case 9:
                    item.goalTypeName = "WEDDING";
                    break;
                case 10:
                    item.goalTypeName = "CUSTOM";
                    break;
                case 11:
                    item.goalTypeName = "JEWELLERY";
                    break;
                default:
                    item.goalTypeName = "NONE";
                    break;
            }
        });

        return goalObj;
    }


    /**
     * @function goToPreviousPage
     * @description for navigate to watchboard page
     */
    goToPreviousPage() {
        this.router.navigate([`${this.previousPagePath}`]);
    }

    /**
     * @function showSubGoal
     * @description show div and subgoal of selected goal id
     * @param selectedId
     */
    showSubGoal(selectedId) {
        this.isShowSubGoal = true;

        this.userService.editGoalByGoalId(selectedId).subscribe((res) => {
            this.subGoalData = res['editGoalList'];
            this.subGoalData = this.subGoalData.filter((subGoal) => {
                return subGoal.editGoalStatus == "NEW" || subGoal.editGoalStatus == "PROCESSING"
            });
        });
    }

    /**
     * @function closeSubGoal
     * @description hide subgoal div
     */
    closeSubGoal() {
        this.isShowSubGoal = false;
    }

    /**
     * @function deleteGoal
     * @description delete goal of selected goal id
     * @param selectedGoal
     */
    deleteGoal(selectedGoal) {

        const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
        confirmModalRef.componentInstance.title = "Confirm Dialog";
        confirmModalRef.componentInstance.body = "Are You Sure To Delete Goal";

        confirmModalRef.result.then((result) => {
            this.spinnerService.start();
            this.userService.deleteAdminGoal(selectedGoal).subscribe((res) => {

                if (res.statusCode == 200) {
                    this.toastService.show("Goal Delete Successfully !", { classname: "bg-success text-light" });
                    this.getCustomerGoalsById(this.userId);
                }

                if (res.statusCode == 500) {
                    this.toastService.show("This Goal Have Portfolio Data, Unable To Delete !", { classname: "bg-danger text-light" });
                }

                this.spinnerService.stop();
            }, (err) => {
                //  error
            });
        }, (reason) => {
            // dismiss
        });

    }

    /**
     * @function deleteGoal
     * @description delete sub-goal of selected sub-goal id
     * @param selectedGoal
     */
    deleteSubGoal(selectedGoal) {

        const deleteData = {
            editGoalList: [selectedGoal],
            filterData: {
                query: "",
                limit: 1,
                skip: 0,
                minAmount: 0,
                maxAmount: 0,
                broker: []
            }
        };

        const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
        confirmModalRef.componentInstance.title = "Confirm Dialog";
        confirmModalRef.componentInstance.body = "Are You Sure To Delete Sub-Goal";

        confirmModalRef.result.then((result) => {
            this.spinnerService.start();
            this.userService.editGoalDeletes(deleteData).subscribe(res => {

                if (res.statusCode == 200) {
                    this.toastService.show("Sub-Goal Delete Successfully !", { classname: "bg-success text-light" });
                    this.getCustomerGoalsById(this.userId);
                }

                if (res.statusCode == 500) {
                    this.toastService.show("Error: Can't Delete Sub-Goal !", { classname: "bg-danger text-light" });
                }

                this.spinnerService.stop();
            }, (err) => {
                //  error
            });

        }, (reason) => {
            // dismiss
        });

    }

    /**
     * @function openEditUserModal
     * @description open edit user details modal
     */
    openEditUserModal() {
        const userUpdateModalRef = this.ngmodalService.open(EditUserModalComponent, this.updateModalOptions);
        userUpdateModalRef.componentInstance.editUserData = this.selectedUserData;
        userUpdateModalRef.componentInstance.mappings = this.mappings;

        userUpdateModalRef.result.then((result) => {
            this.getUserById(this.userId);
        },
            (reason) => {
            }
        );
    }

    getAllFrequency() {
        let paymentPlans;
        this.paymentService.getAllFrequency().subscribe((res) => {
          paymentPlans = res && res.statusCode ? res.data : [];
          paymentPlans = paymentPlans.filter((plan) => {
            plan.text = this.planMap[plan.frequency];
            return plan.frequency in this.planMap;
          });
    
          this.paymentPlans = paymentPlans;
        });
      }

    openCreatePaymentLinkModal(){
        const paymentLinkModalRef = this.ngmodalService.open(CreatePaymentLinkModalComponent, this.lgModalOptions);
        paymentLinkModalRef.componentInstance.userId = this.userId;
        paymentLinkModalRef.componentInstance.paymentPlans = this.paymentPlans;

        paymentLinkModalRef.result.then((result) => {
            // this.getUserById(this.userId);
            this.getPaymentLinks();
        },
            (reason) => {
                
            }
        );

    }

    /**
     * @function changeUserStatus
     * @description change user status to LOCKED or ACTIVE
     */
    changeUserStatus() {
        let status;
        if (this.selectedUserData.status === 'ACTIVE') {
            status = 'LOCKED'
        } else {
            status = 'ACTIVE'
        }

        this.spinnerService.start();
        this.userService.updateUser({ id: this.selectedUserData.id, filters: { status } }).subscribe((res) => {
            this.getUserById(this.userId);
            this.spinnerService.stop();
        })
    }

    generateEOM() {
        let reqBody = {
            userId: this.userId,
            goalsId: this.selectedEOMGoals.map((s) => s.goalId)
        }
        this.generatingEOM = !this.generatingEOM;
        this.userService.generateEOMForUser(reqBody).subscribe((res) => {
            if (res) {
                let sucE = res.editIds && res.editIds.length ? res.editIds.map((a) => a.name).join(",") : "";
                this.eomMsg.success = sucE ? `EditGoal generated for all this goals ${sucE}` : "";
                let isError = res.goalsForManualProcessing && res.goalsForManualProcessing.length ? res.goalsForManualProcessing.map((a) => a.goalName).join(",") : "";
                this.eomMsg.error = isError ? `Following goals already have editGoal under processing ${isError}` : "";
                this.eomMsg.error = this.eomMsg.error ? this.eomMsg.error : res.msg;
                this.getCustomerGoalsById(this.userId);
                this.selectedEOMGoals = [];
            }
            this.generatingEOM = !this.generatingEOM;
        })
    }
}
