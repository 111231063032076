import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  constructor() { }

  title: BehaviorSubject<string> = new BehaviorSubject<string>('Watchboard');

  /**
   * @function setTitle
   * @description Push the accepted title into variant
   * @param titleValue 
   */
  setTitle(titleValue: string) {
    this.title.next(titleValue);
  }

}
