import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userForm: FormGroup;
  passwordType = "password"
  error: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  /**
   * @function ngOnInit
   * @description Initializes userForm for login
   */
  ngOnInit() {
    this.userForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  /**
   * @function showHidePassword
   * @description toggeling between show and hide the password
   */
  showHidePassword() {
    this.passwordType === "password" ? this.passwordType = "text" : this.passwordType = "password";
  }

  /**
   * @function onSubmit
   * @description login with username and password
   * @param userF 
   */
  onSubmit(userF) {
    this.auth.login(userF.value.username, userF.value.password)
      .pipe(first())
      .subscribe(
        result => this.router.navigate(['/dashboard']),
        err => this.error = 'Could not authenticate'
      );
  }

}
