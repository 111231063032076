import {
  Component,
  OnInit
} from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/core/shared/services/toast.service';
import { ConfirmModalComponent } from 'src/app/core/dialogs/confirm-modal/confirm-modal.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { TradePriceUpdateModalComponent } from './trade-price-update-modal/trade-price-update-modal.component';

@Component({
  selector: 'app-processing-edit-goals',
  templateUrl: './processing-edit-goals.component.html',
  styleUrls: ['./processing-edit-goals.component.scss']
})
export class ProcessingEditGoalsComponent implements OnInit {

  p: number = 1;
  userCount: number;
  filterQuery = "";
  rowsOnPage = 25;
  processingGoalData = [];
  tradeData = [];

  confirmModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    size: 'sm',
    centered: true,
  };

  updateModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    size: 'lg',
    centered: true,
  };

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private spinnerService: SpinnerService,
    private router: Router,
    private toastService: ToastService,
    private ngmodalService: NgbModal
  ) { }

  /**
   * @function ngOnInit
   * @description Set header title
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.pageTitleService.setTitle("Processing Edit Goal");

    this.getProcessingGoalData(this.rowsOnPage, this.p);
  }

  /**
   * @function getProcessingGoalData
   * @description get the processing goals data
   * @param limit 
   * @param page 
   */
  getProcessingGoalData(limit, page) {
    const skip = page === 1 ? 0 : ((page - 1) * limit);
    this.userService.getProcessingGoalData(limit, skip).subscribe((res) => {
      if (res) {
        this.processingGoalData = res["data"]["editgoalData"];
        this.userCount = res["data"]["count"];
      }
    })
  }

  /**
 * @function onPageChange
 * @description on page change event fire,return current page number
 * @param activePage 
 */
  onPageChange(activePage) {
    document.documentElement.scrollTop = 0;

    this.p = activePage;
    this.getProcessingGoalData(this.rowsOnPage, this.p);
  }

  /**
 * @function goToLeadsPage
 * @description for navigate to leads page with tavaga-id
 * @param tavagaid
 */
  goToLeadsPage(tavagaid) {
    this.userService.setRouteData('/dashboard/goal-management/processing-edit-goals');
    this.router.navigate(["/dashboard/leads", tavagaid]);
  }

  /**
   * @function deleteProcessingGoal
   * @description delete processing goal of selected goal id
   * @param selectedGoal
   */
  deleteProcessingGoal(selectedGoal) {

    // const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
    // confirmModalRef.componentInstance.title = "Confirm Dialog";
    // confirmModalRef.componentInstance.body = "Are You Sure To Delete Processing Goal";

    // confirmModalRef.result.then((result) => {
    //   this.spinnerService.start();
    //   this.userService.deleteAdminGoal(selectedGoal).subscribe((res) => {

    //     if (res.statusCode == 200) {
    //       this.toastService.show("Processing Goal Delete Successfully !", { classname: "bg-success text-light" });
    //     }

    //     if (res.statusCode == 500) {
    //       this.toastService.show("This Goal Have Portfolio Data, Unable To Delete !", { classname: "bg-danger text-light" });
    //     }

    //     this.spinnerService.stop();
    //   }, (err) => {
    //     //  error
    //   });
    // }, (reason) => {
    //   // dismiss
    // });

  }

  /**
   * @function viewTradeDetails
   * @description view trade details of selected goal id
   * @param EditGoalId
   */
  viewTradeDetails(EditGoalId) {
    const tradeInfo = {
      editGoalId: EditGoalId
    }

    this.userService.getProcessingTradeData(tradeInfo).subscribe((res) => {
      this.tradeData = res ? res : [];
    })

    if (this.tradeData.length) {

      const updateModalRef = this.ngmodalService.open(TradePriceUpdateModalComponent, this.updateModalOptions);
      updateModalRef.componentInstance.tradeData = this.tradeData;

      updateModalRef.result.then(
        (result) => {
          this.getProcessingGoalData(this.rowsOnPage, this.p);
        },
        (reason) => {

        }
      );

    } else {
      this.toastService.show("This EditGoal Has No Trades", { classname: "bg-danger text-light" });
    }

  }


  /**
   * @function downloadReport
   * @description download report in excel
   */
  downloadReport() {
    let report = [...this.processingGoalData];
    let reportArr = [];
    report.forEach(element => {
      reportArr.push({
        EDITGOAL_ID: element.id ? element.id : "-",
        GOAL_ID: element.goalId ? element.goalId : "-",
        TAVAGA_ID: element.goal.userId ? element.goal.userId : "-",
        EDIT_TYPE: element.editType ? element.editType : "-",
        GOAL_NAME: element.name ? element.name : "-",
        TRADE_STATUS: element.trade ? element.trade : "-",
        TTG: element.timeToGoal ? element.timeToGoal : "-",
        MONTHALY_AMT: element.monthlyAmount ? element.monthlyAmount : "-"
      });
    });

    this.excelService.exportAsExcelFile(
      reportArr,
      new Date().toISOString().slice(0, 10) + "-proc-edit-goal-data"
    );
  }

}
