import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoupounService } from '../../../services/coupoun/coupoun.service';
import { PaymentService } from '../../../services/payment/payment.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";



@Component({
  selector: 'app-crud-discount',
  templateUrl: './crud-discount.component.html',
  styleUrls: ['./crud-discount.component.scss']
})

export class CrudDiscountComponent implements OnInit {
  @Input() coupounObj: any;
  @Input() activePackage: any = { frequency: "", noOfDays: "", amount: 0 };
  @Input() paymentFrequency = [];
  coupounForm: FormGroup;
  validCoupoun = true;
  constructor(private coupounService: CoupounService, private fb: FormBuilder, private calendar: NgbCalendar,
    public activeModal: NgbActiveModal, private paymentService: PaymentService) { }



  ngOnInit() {
    this.initiForm();
    this.changeEvent();
  }

  initiForm() {
    this.coupounForm = this.fb.group({
      id: [""],
      discountInFlat: ["", Validators.compose([Validators.required])],
      paymentFrequencyId: ["1", Validators.required],
      discountCode: ["", Validators.compose([Validators.required, Validators.minLength(6)])],
      companyName: ["", Validators.compose([Validators.required])],
      description: ["", Validators.compose([Validators.required])],
      coupounStartAt: [{ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }, Validators.compose([Validators.required])],
      coupounEndAt: [null],
      numberOfUsers: [null]
    });
    this.updateCoupoun();
  }

  updateCoupoun() {
    if (this.coupounObj && this.coupounObj.id) {
      let tempObj = JSON.parse(JSON.stringify(this.coupounObj));
      let coupounStartAt = { year: new Date(tempObj.coupounStartAt).getFullYear(), month: new Date(tempObj.coupounStartAt).getMonth() + 1, day: new Date(tempObj.coupounStartAt).getDate() };

      let coupounEndAt = tempObj.coupounEndAt ? { year: new Date(tempObj.coupounEndAt).getFullYear(), month: new Date(tempObj.coupounEndAt).getMonth() + 1, day: new Date(tempObj.coupounEndAt).getDate() } : null;

      tempObj.coupounStartAt = coupounStartAt;
      tempObj.coupounEndAt = coupounEndAt;

      this.coupounForm.patchValue(tempObj);
      this.coupounForm.get('discountInFlat').disable();
      this.coupounForm.get('paymentFrequencyId').disable();
      this.coupounForm.get('discountCode').disable();
    }
  }

  get f() { return this.coupounForm.controls; }


  changeEvent() {
    let discountCodeValue: any = this.coupounForm.get('discountCode');
    discountCodeValue.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(val => {
        if (val.length >= 6) {
          let tempVal = val.toUpperCase();
          this.coupounService.checkCouponExists({ discountCode: tempVal}).subscribe((exists) => {
            this.validCoupoun = !exists;
          })
        } else {
          this.validCoupoun = true;
        }
      });
  }

  saveCoupoun() {
    let reqBody = this.coupounForm.value;
    if (this.coupounForm.valid && !reqBody.id) {
      reqBody = this.prepareReqObj(reqBody);
      this.coupounService.addCoupoun(reqBody).subscribe((res) => {
        this.activeModal.close(res);
      });
    } else {
      reqBody = this.prepareReqObj(reqBody);
      this.coupounService.editCoupoun(reqBody).subscribe((res) => {
        this.activeModal.close(res);
      })
    }
  }

  prepareReqObj(reqBody) {
    let coupounStartAt = new Date(reqBody.coupounStartAt.year, reqBody.coupounStartAt.month - 1, reqBody.coupounStartAt.day);
    let coupounEndAt = reqBody.coupounEndAt ? new Date(reqBody.coupounEndAt.year, reqBody.coupounEndAt.month - 1, reqBody.coupounEndAt.day) : null
    reqBody.coupounStartAt = coupounStartAt;
    reqBody.coupounEndAt = coupounEndAt;
    return reqBody;
  }

}
