import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';
import * as moment from "moment";
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { ToastService } from 'src/app/core/shared/services/toast.service';

@Component({
  selector: 'app-edit-goal',
  templateUrl: './edit-goal.component.html',
  styleUrls: ['./edit-goal.component.scss']
})
export class EditGoalComponent implements OnInit {

  editGoalData = [];
  filteredGoalData = [];
  filterQuery = "";
  rowsOnPage = 10;

  // date range picker related
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  range: Range = { fromDate: new Date(), toDate: new Date() };

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private router: Router,
    private toastService: ToastService
  ) { }

  /**
    * @function ngOnInit
    * @description Set header title
    */
  ngOnInit() {
    document.documentElement.scrollTop = 0;

    this.userService.userGoalData.subscribe((res) => {

      if (res) {
        this.pageTitleService.setTitle('Change ' + res['name'] + ' EditGoal Details');
        this.editGoalData = res["editGoal"];
      } else {
        this.router.navigate(["dashboard/reports/trading-details/goals"]);
      }

    })

    const today = new Date();
    const yester = new Date(new Date().setDate(new Date().getDate() - 30));
    this.setupPresets();

    this.options = {
      presets: this.presets,
      format: "mediumDate",
      range: {
        fromDate: yester,
        toDate: today
      },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false
      }
    };

  }

  /**
    * @function getEditedGoals
    * @description get edited goal details of selected daterange
    * @param dateObj
    */
  getEditedGoals(dateObj) {
    if (this.editGoalData) {

      this.filteredGoalData = [];

      this.editGoalData.forEach(element => {
        if (new Date(element.created).getTime() >= dateObj.fromDate &&
          new Date(element.created).getTime() <= dateObj.toDate) {
          this.filteredGoalData.push(element);
        }
      });
    }

  }

  /**
   * @function updateRange
   * @description handler function that receives the updated date range object
   * @param range
   */
  updateRange(range: Range) {
    this.range = range;
    let dateObj = {
      fromDate: this.range.fromDate.getTime(),
      toDate: this.range.toDate.getTime()
    };
    this.getEditedGoals(dateObj);
  }

  /**
   * @function setupPresets
   * @description helper function to create initial presets
   */
  setupPresets() {
    const backDate = numOfDays => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: "Yesterday",
        range: { fromDate: yesterday, toDate: today }
      },
      {
        presetLabel: "Last 7 Days",
        range: { fromDate: minus7, toDate: today }
      },
      {
        presetLabel: "Last 30 Days",
        range: { fromDate: minus30, toDate: today }
      },
      {
        presetLabel: "This Month",
        range: { fromDate: currMonthStart, toDate: currMonthEnd }
      },
      {
        presetLabel: "Last Month",
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }

  /**
   * @function goToGoalPage
   * @description navigate to goal page
   */
  goToGoalPage() {
    this.router.navigate(["dashboard/reports/trading-details/goals"]);
  }

  /**
   * @function goToTradesPage
   * @description navigate to trades page with selected editGoalId
   * @param selectedEditGoalData
   */
  goToTradesPage(selectedEditGoalData) {
    this.userService.setUserTradeData(selectedEditGoalData);
    this.router.navigate(["dashboard/reports/trading-details/trades"]);
  }

  /**
   * @function noTrades
   * @description display alert when selectedGoalId has no trades
   */
  noTrades() {
    this.toastService.show('This Editgoal Has No Trades', { classname: 'bg-danger text-light' })
  }

  /**
   * @function downloadReport
   * @description download report in excel
   */
  downloadReport() {
    let report = [...this.filteredGoalData];
    let reportArr = [];
    report.forEach(element => {
      reportArr.push({
        NAME: element.name ? element.name : '-',
        TARGET_AMOUNT: element.targetAmount ? element.targetAmount : '-',
        MONTHALY_AMOUNT: element.monthlyAmount ? element.monthlyAmount : '-',
        INVESTED_AMOUNT: element.investedAmount ? element.investedAmount : '-',
        TIME_TO_GOAL: element.timeToGoal ? element.timeToGoal : '-',
        EDIT_GOAL_STATUS: element.goalStatus ? element.goalStatus : '-',
        CREATED: element.created ? element.created : '-',
        GOAL_TYPE: element.goalType ? element.goalType : '-',
        GOAL_STATUS: element.goalStatus ? element.goalStatus : '-',
        CASH: element.cash ? element.cash : '-',
        TRADE: element.trade ? element.trade : '-',
        INVESTED_CASH_DIFFERENCE: element.investedCashDifference ? element.investedCashDifference : '-',
        TRADE_ATTEMPT: element.tradeAttempt ? element.tradeAttempt : '-',
        AMOUNT_IS_OTB_OR_SIP: element.amtIsOtborSip ? element.amtIsOtborSip : '-',
        ID: element.id ? element.id : '-',
        GOAL_ID: element.goalId ? element.goalId : '-',
      });
    });

    this.excelService.exportAsExcelFile(reportArr, moment(this.range.fromDate).format("YYYY-MM-DD") +
      "-to-" + moment(this.range.toDate).format("YYYY-MM-DD") + "-edit-goal-records");
  }

}
