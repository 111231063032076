import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-goal-details',
  templateUrl: './goal-details.component.html',
  styleUrls: ['./goal-details.component.scss']
})
export class GoalDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
