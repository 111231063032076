import {
    Component,
    OnInit,
    Input
} from '@angular/core';
import {
    FormGroup,
    FormControl,
    Validators
} from "@angular/forms";
import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-edit-user-modal',
    templateUrl: './edit-user-modal.component.html',
    styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent implements OnInit {

    @Input() editUserData: any;
    @Input() mappings: any;

    // followup date related
    updateDate = moment(new Date()).format("YYYY-DD-MM");

    // form related
    editUserForm: FormGroup;

    levels = [1, 2, 3, 4, 5];
    tradeDays = [1, 2, 3, 4];
    NRI = [
        {
            id: 0,
            isNRI: "Non-NRI Customer",
            value: false
        },
        {
            id: 1,
            isNRI: "NRI Customer",
            value: true
        }
    ];

    constructor(
        public activeModal: NgbActiveModal,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private toastService: ToastService
    ) { }


    /**
     * @function ngOnInit
     * @description for initial editUserForm form build
     */
    ngOnInit() {

        let dobObject;
        if (this.editUserData.userKyc && this.editUserData.userKyc.dob) {
            const dobStr = this.editUserData.userKyc.dob;
            const dobDate = new Date(dobStr);
            dobObject = {
                year: dobDate.getFullYear(),
                month: dobDate.getMonth() + 1,
                day: dobDate.getDate()
            }
        }

        this.editUserForm = new FormGroup({
            // tavagaId: new FormControl(this.editUserData['id']),
            phone: new FormControl(this.editUserData.phone),
            dob: new FormControl({ value: dobObject, disabled: !Boolean(this.editUserData.userKyc) }),
            email: new FormControl(this.editUserData.email),
            gender: new FormControl(this.editUserData.gender),
            pan: new FormControl((this.editUserData.userKyc && this.editUserData.userKyc.pan) ? this.editUserData.userKyc.pan : ""),
            givenName: new FormControl(this.editUserData.givenName),
            familyName: new FormControl(this.editUserData.familyName),
            riskId: new FormControl(this.editUserData.riskId),
            day: new FormControl({
                value: (this.editUserData.userKyc && this.editUserData.userKyc.bankDetails && this.editUserData.userKyc.bankDetails.day) ? this.editUserData.userKyc.bankDetails.day : "",
                disabled: !Boolean(this.editUserData.userKyc && this.editUserData.userKyc.bankDetails)
            }),
            isNRI: new FormControl(this.editUserData.isNRI),
            city: new FormControl(this.editUserData.city),
            state: new FormControl(this.editUserData.state),
            pin: new FormControl(this.editUserData.pin),
            address: new FormControl(this.editUserData.address),
        });
    }

    getDirtyValues() {
        const obj = {};
        _.forOwn(this.editUserForm.controls, (control, key) => {
            if (control.dirty) {
                obj[key] = control.value;
            }
        });
        return obj;
    }

    /**
     * @function updateUser
     * @description update user details of selected tavaga user
     */
    updateUser() {
        if (this.editUserForm.invalid) {
            return;
        }

        this.spinnerService.start();
        const changedFields = this.getDirtyValues();
        if (_.isEmpty(changedFields)) {
            this.toastService.show('No Field is changed !', { classname: 'bg-danger text-light' })
            this.spinnerService.stop();
            return;
        }
        const dob = changedFields['dob'];
        if (dob && typeof dob === 'object') {
            const dobString = `${dob.year}-${dob.month}-${dob.day}`;
            changedFields['dob'] = dobString;
        }

        let reqBody = {
            id: this.editUserData['id'],
            filters: changedFields
        }

        this.userService.updateUser(reqBody).subscribe((res) => {
            this.spinnerService.stop();
            this.activeModal.close('Ok click');
            this.toastService.show('User Details Updated Successfully !', { classname: 'bg-success text-light' })
        },
            (err) => {
                this.spinnerService.stop();
            })
    }

}
