import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';


@Component({
  selector: 'app-trade-price-update-modal',
  templateUrl: './trade-price-update-modal.component.html',
  styleUrls: ['./trade-price-update-modal.component.scss']
})
export class TradePriceUpdateModalComponent implements OnInit {

  @Input() tradeData = [];
  @Output() userOptionData = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
  }

  /**
   * @function dataPassToParent
   * @description stored data send to parent via emit() method
   */
  dataPassToParent(updatedTradeData: Array<any>) {

    updatedTradeData = updatedTradeData.filter((item) => {
      if (item.tradesStatus == "LIVE") {
        item.actualPrice = item.netPrice;
        item.actualQuantity = item.quantity;
        return item;
      }
    });

    if (updatedTradeData.length) {
      this.spinnerService.start();
      this.userService.recordTransanctions(updatedTradeData).subscribe((item) => {
        this.toastService.show("Quantity and Price Updated Successfully !", { classname: "bg-success text-light" });
        this.spinnerService.stop();
      },
        (error) => {
          this.spinnerService.stop();
        })
    } else {
      this.toastService.show("'LIVE' trade status records not found for update !", { classname: "bg-danger text-light" });
    }

    this.userOptionData.emit(updatedTradeData);
    this.activeModal.close('Ok click');
  }

}
