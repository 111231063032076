import { 
  Component, 
  OnInit 
} from '@angular/core';
import { 
  NgxDrpOptions, 
  PresetItem, 
  Range
 } from 'ngx-mat-daterange-picker';
import { PageTitleService } from 'src/app/services/page-title.service';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';
import * as moment from "moment";
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';

@Component({
  selector: 'app-goal-count-report',
  templateUrl: './goal-count-report.component.html',
  styleUrls: ['./goal-count-report.component.scss']
})
export class GoalCountReportComponent implements OnInit {

  data: any[];
  filterQuery = "";
  rowsOnPage = 20;

  // date range picker related
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  range: Range = { fromDate: new Date(), toDate: new Date() };

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private router: Router) { }

  /**
   * @function ngOnInit
   * @description Set header title
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.pageTitleService.setTitle('Goal Count Report');

    const today = new Date();
    const yester = new Date(new Date().setDate(new Date().getDate() - 30));
    this.setupPresets();

    this.options = {
      presets: this.presets,
      format: "mediumDate",
      range: {
        fromDate: yester,
        toDate: today
      },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false
      }
    };

  }

  /**
   * @function getGoalCountReport
   * @description get goal count report details of selected daterange
   * @param dateObj
   */
  getGoalCountReport(dateObj) {
    this.spinnerService.start();
    this.userService.getGoalCountReport(dateObj).subscribe(
      data => {
        if (data["getGoalMakingReport"].length > 0) {
          this.data = data["getGoalMakingReport"];
          this.spinnerService.stop();
        } else {
          this.data = [];
          this.toastService.show(`no record found`, { classname: "bg-danger text-light", delay: 5000 });
          this.spinnerService.stop();
        }
      },
      err => {
        this.spinnerService.stop()
      }
    );
  }

  /**
   * @function updateRange
   * @description handler function that receives the updated date range object
   * @param range
   */
  updateRange(range: Range) {
    this.range = range;
    let dateObj = {
      fromDate: moment(this.range.fromDate).format("YYYY-MM-DD"),
      toDate: moment(this.range.toDate).format("YYYY-MM-DD"),
      offlineKyc: true
    };
    this.getGoalCountReport(dateObj);
  }

  /**
   * @function setupPresets
   * @description helper function to create initial presets
   */
  setupPresets() {
    const backDate = numOfDays => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: "Yesterday",
        range: { fromDate: yesterday, toDate: today }
      },
      {
        presetLabel: "Last 7 Days",
        range: { fromDate: minus7, toDate: today }
      },
      {
        presetLabel: "Last 30 Days",
        range: { fromDate: minus30, toDate: today }
      },
      {
        presetLabel: "This Month",
        range: { fromDate: currMonthStart, toDate: currMonthEnd }
      },
      {
        presetLabel: "Last Month",
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }

  /**
   * @function downloadReport
   * @description download report in excel
   */
  downloadReport() {
    let report = [...this.data];
    let reportArr = [];
    report.forEach(element => {
      reportArr.push({
        DATE: moment(element.date).format('MMMM Do YYYY'),
        HOME_FUND: element.obj.HOUSE ? element.obj.HOUSE : '-',
        STUDY_BUDDY: element.obj.EDUCATION ? element.obj.EDUCATION : '-',
        JEWELLERY: element.obj.JEWELLERY ? element.obj.JEWELLERY : '-',
        WORLD_CUP: element.obj.CRICKET ? element.obj.CRICKET : '-',
        GO_FOR_GOAL: element.obj.FOOOTBALL ? element.obj.FOOOTBALL : '-',
        VACATION_FUND: element.obj.FOREIGN ? element.obj.FOREIGN : '-',
        COOL_STUFF: element.obj.SWAG ? element.obj.SWAG : '-',
        DREAM_CAR: element.obj.CAR ? element.obj.CAR : '-',
        WOW_WEDDING: element.obj.WEDDING ? element.obj.WEDDING : '-',
        WEALTH_MAX: element.obj.WEALTH_MAX ? element.obj.WEALTH_MAX : '-',
        EMERGENCY_FUND: element.obj.EMERGENCY_FUND ? element.obj.EMERGENCY_FUND : '-',
        CUSTOM_GOAL: element.obj.CUSTOM ? element.obj.CUSTOM : '-'
      });
    });

    this.excelService.exportAsExcelFile(reportArr, moment(this.range.fromDate).format("YYYY-MM-DD") +
      "-to-" + moment(this.range.toDate).format("YYYY-MM-DD") + "-goal-making");
  }

}
