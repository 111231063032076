import { Component, OnInit } from '@angular/core';
import { TradeTerminalService } from '../../services/trade/trade-terminal.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/core/dialogs/confirm-modal/confirm-modal.component';
import { ToastService } from 'src/app/core/shared/services/toast.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ExcelService } from 'src/app/services/excel.service';


declare let $: any;
@Component({
  selector: 'app-basket-allocations',
  templateUrl: './basket-allocations.component.html',
  styleUrls: ['./basket-allocations.component.scss']
})
export class BasketAllocationsComponent implements OnInit {
  limitGoals = 10;
  basketAllocations = [];
  basketCount = 0;
  apiCall = false;
  basketFilters = { currenPage: 1, limit: this.limitGoals, skip: 0, filters: { brokerName: [], goalId: [], tavagaID: null } };
  filterSearch = new Subject<string>();
  brokerList = [];
  limitedSelected = false;
  selectAll = false;
  selectedGoalIds = [];
  confirmModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    size: 'lg',
    centered: true,
  };
  dropdownSettings: IDropdownSettings = {};
  selectedBroker = [];

  constructor(private tradeTerminalService: TradeTerminalService,
    private ngmodalService: NgbModal,
    private toastService: ToastService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    distinctUntilChanged
    this.getBasketCount();
    this.getBasketList();
    this.debounceSearch();
    this.initPlugin();
  }

  initPlugin() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }

  getBasketCount() {
    this.tradeTerminalService.getBasketAllocationCount(this.basketFilters)
      .subscribe((res) => {
        if (res && res.status_code == 1) {
          this.basketCount = res.resp.countGoalId;
          if (!this.brokerList.length) {
            let brokers = [];
            res.resp.brokerName.forEach((b) => {
              brokers.push({ 'item_id': b, 'item_text': b });
            });
            this.brokerList = brokers;
          }
          console.log(this.brokerList);
        }
      })
  }

  getBasketList() {
    this.apiCall = true;
    this.limitedSelected = false;
    this.tradeTerminalService.getBasketList(this.basketFilters).subscribe((res) => {
      if (res && res.args.status_code == 1) {
        this.apiCall = false;
        this.basketAllocations = Object.values(res.args.data);
        this.selectAll && this.eventSelectLimited(this.selectAll);
        this.limitedSelected = this.selectAll ? this.selectAll : this.limitedSelected;
      }
    });
  }

  expandBasket(goalId: number) {
    $(`#goal${goalId}`).collapse('toggle')
  }

  getTotalbasket(basket) {
    let amount = 0;
    amount = basket.reduce((a, v) => a + (v.quantity * v.price), amount);
    return amount;
  }

  getRoundOff(basketAmount: number) {
    return basketAmount ? basketAmount.toFixed(2) : 0.00;
  }

  changeLimit(event: any) {
    this.basketFilters.currenPage = 1;
    this.basketFilters.skip = 0;
    this.getBasketCount();
    this.getBasketList();
  }

  onPageChange(pageIndex: any) {
    this.basketFilters.currenPage = pageIndex;
    this.basketFilters.skip = (pageIndex - 1) * this.basketFilters.limit;
    this.getBasketList();
  }

  getNewRecords() {
    this.basketFilters = { currenPage: 1, limit: this.limitGoals, skip: 0, filters: { brokerName: [], goalId: [], tavagaID: null } };
    this.getBasketCount();
    this.getBasketList();
  }

  debounceSearch() {
    // Debounce search.
    this.filterSearch.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.basketFilters.currenPage = 1;
        this.basketFilters.limit = this.limitGoals;
        this.basketFilters.skip = 0;
        this.limitedSelected = false;
        this.selectAll = false;
        this.getBasketCount();
        this.getBasketList();
      });
  }

  eventSelectLimited(isSelected) {
    if (this.basketAllocations) {
      this.basketAllocations.forEach(basket => {
        let goalId = basket[0].goalId;
        if (isSelected && !this.selectedGoalIds.includes(goalId)) {
          this.selectedGoalIds.push(goalId);
        } if (!isSelected) {
          this.selectedGoalIds = this.selectedGoalIds.filter((s) => s != goalId);
        }
      });
    }
  }

  eventSelectAll() {
    this.selectAll = !this.selectAll;
    this.selectAll && this.eventSelectLimited(this.selectAll);
    //On clear selection uncheck previous page selected and set current page selected only
    if (!this.selectAll) {
      let goalIds = [];
      this.basketAllocations.forEach(basket => {
        let goalId = basket[0].goalId;
        goalIds.push(goalId);
      });
      this.selectedGoalIds = goalIds;
    }
  }

  isSelected(goalId) {
    return this.selectedGoalIds.includes(goalId);
  }

  eventInvidiualGoal(event: any, goalId: number) {
    const { checked } = event.target;
    this.limitedSelected = false;
    this.selectAll = false;
    if (checked) {
      this.selectedGoalIds.push(goalId);
    } else {
      this.selectedGoalIds = this.selectedGoalIds.filter((s) => s != goalId);
    }
  }

  confirmModelTransaction() {
    const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
    confirmModalRef.componentInstance.title = "Confirm Dialog";
    const msg = 'Are you sure to generate transaction for';
    confirmModalRef.componentInstance.body = this.selectAll ? `${msg} ${this.basketCount} goals` : `${msg} ${this.selectedGoalIds.length} goals`;
    confirmModalRef.result.then((result) => {
      this.generateTradeTransactions();
    }, (reason) => { });
  }

  confirmDeleteModelTransaction() {
    const confirmModalRef = this.ngmodalService.open(ConfirmModalComponent, this.confirmModalOptions);
    confirmModalRef.componentInstance.title = "Confirm Dialog";
    const msg = 'Are you sure to delete transaction for';
    confirmModalRef.componentInstance.body = this.selectAll ? `${msg} ${this.basketCount} goals` : `${msg} ${this.selectedGoalIds.length} goals`;
    confirmModalRef.result.then((result) => {
      this.deleteTrades();
    }, (reason) => { });
  }

  downloadResults() {
    if (!this.basketAllocations.length) {
      return;
    }
    this.tradeTerminalService.downloadTrades().subscribe(s => {
      this.excelService.saveAsExcelFile(s, 'basket-allocations');
    });
  }

  generateTradeTransactions() {
    if (this.basketAllocations && this.selectAll) {
      this.basketFilters.limit = this.basketCount;
      this.basketFilters.skip = 0;
      this.executeTrades(this.basketFilters);
    } else if (this.basketAllocations && (this.selectedGoalIds.length || this.limitedSelected)) {
      this.basketFilters.filters.goalId = this.selectedGoalIds;
      this.basketFilters.limit = this.selectedGoalIds.length;
      this.basketFilters.skip = 0;
      this.executeTrades(this.basketFilters);
    } else {
      this.toastService.show(`Select goal to generate trade`, { classname: "bg-danger text-light", delay: 8000 });
    }
  }

  executeTrades(reqBody: any) {
    this.tradeTerminalService.generateTrades(reqBody).subscribe((res) => {
      console.log(res);
      this.getNewRecords();
      this.limitedSelected = false;
      this.selectAll = false;
      this.selectedGoalIds = [];
      this.selectedBroker = [];
      this.toastService.show("Request under process!", { classname: "bg-success text-light", delay: 5000 });
    })
  }

  executeDeleteTrades(reqBody: any) {
    this.tradeTerminalService.deleteTrades(reqBody).subscribe((res) => {
      console.log(res);
      this.getNewRecords();
      this.limitedSelected = false;
      this.selectAll = false;
      this.selectedGoalIds = [];
      this.selectedBroker = [];
      this.toastService.show("Request under process!", { classname: "bg-success text-light", delay: 5000 });
    })
  }

  deleteTrades() {
    if (this.basketAllocations && this.selectAll) {
      this.basketFilters.limit = this.basketCount;
      this.basketFilters.skip = 0;
      this.executeDeleteTrades(this.basketFilters);
    } else if (this.basketAllocations && (this.selectedGoalIds.length || this.limitedSelected)) {
      this.basketFilters.filters.goalId = this.selectedGoalIds;
      this.basketFilters.limit = this.selectedGoalIds.length;
      this.basketFilters.skip = 0;
      this.executeDeleteTrades(this.basketFilters);
    } else {
      this.toastService.show(`Select goal to delete trade`, { classname: "bg-danger text-light", delay: 8000 });
    }
  }

  onBrokerSelect(broker: any, isSelect: boolean) {
    // const brokerName = broker.item_id;
    const brokerName = broker;
    if (isSelect) {
      this.basketFilters.filters.brokerName.push(brokerName);
    } else {
      this.basketFilters.filters.brokerName = this.basketFilters.filters.brokerName.filter((b) => b != brokerName);
    }
    this.getBasketList();
    this.getBasketCount();
  }
}
