import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from '../services/user.service';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {

  @ViewChild('sidenav', { static: true }) sidebar: ElementRef;

  reportIcon = "arrow_drop_down";
  isCollapsed = true;

  goalIcon = "arrow_drop_down";
  isCollapsedGoal = true;

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

  /**
   * @function collapseMenu
   * @description set icon to the collapsible menu
   */
  collapseMenu() {
    this.isCollapsed = !this.isCollapsed;
    this.reportIcon == "arrow_drop_down"
      ? (this.reportIcon = "arrow_drop_up")
      : (this.reportIcon = "arrow_drop_down");
  }

  /**
   * @function collapseGoalMenu
   * @description set icon to the collapsible menu
   */
  collapseGoalMenu() {
    this.isCollapsedGoal = !this.isCollapsedGoal;
    this.goalIcon == "arrow_drop_down"
      ? (this.goalIcon = "arrow_drop_up")
      : (this.goalIcon = "arrow_drop_down");
  }

  /**
   * @function toggleSidebar
   * @description Use for Collapse Menu
   */
  toggleSidebar() {
    this.sidebar.nativeElement.classList.toggle('collapsed');
  }

}
