import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WatchboardService {

  baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }

  /**
    * @function getActiveTemplates
    * @description Get all active templates from sendgrid
    */
  getActiveTemplates(): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/getActiveTemplates`);
  }

  // watchboard page

  /**
   * @function getFilteredData
   * @description return new filtered data
   * @param filterQuery
   */
  getUserFilteredData(filterQuery: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/watchboard/fetchUserData`, filterQuery);
  }

  getUserCount(filterQuery: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/watchboard/fetchUserCount`, filterQuery);
  }

  getUserBrokers(): Observable<any>{
    return this.http.get(`${this.baseUrl}/watchboard/getUserBrokers`);
  }

  downloadUserData(body): Observable<any> {
    return this.http.post(`${this.baseUrl}/watchboard/downloadUserData`, body, {
      responseType: 'blob' 
    });
  }

  /**
  * @function getMasterData
  * @description return master data for watchboard
  */
  getMasterData(): Observable<any> {
    return this.http.get(`${this.baseUrl}/watchboard/masterData`);
  }


  /**
   * @function sendNotification
   * @description send notifications(simple,blog,video) to selected users
   * @param query 
   */
  sendFirebaseNotification(query: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/sendFirebaseNotification`, query);
  }


  /**
 * @function sendSms
 * @description send sms to selected users
 * @param query 
 */
  sendSms(query: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/sendSms`, query);
  }

  /**
   * @function sendEmail
   * @description send email to selected users
   * @param query 
   */
  sendMail(query: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/sendEmail`, query);
  }

  /**
     * @function
     * @description 
     * @param query 
     */
  getAutoNotification(): Observable<any> {
    return this.http.get(`${this.baseUrl}/dailyNotifications`);
  }


  /**
   * @function 
   * @description add to selected users
   * @param query 
   */
  addAutoNotification(postBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/dailyNotifications`, postBody);
  }

  putAutoNotification(putBody: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/dailyNotifications`, putBody);
  }
}
