import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  /**
   * @function login
   * @description get username and password and return different token for each request
   * @param username 
   * @param password 
   */
  login(username: string, password: string): Observable<boolean> {
    return this.http.post(`${this.baseUrl}/users/login`, { email: username, password: password })
      .pipe(
        map(result => {
          localStorage.setItem('access_token', JSON.stringify(result));
          return true;
        })
      );
  }

  /**
   * @function logout
   * @description logout and navigate to login page
   */
  logout() {
    // localStorage.removeItem('access_token');
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  /**
   * @property loggedIn
   * @description return true if token is stored in localstorage otherwise return false
   */
  get loggedIn(): boolean {
    return (localStorage.getItem('access_token') !== null);
  }

}
