import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  /**
  * @function getPaymetInfo
  * @description 
  */
  getPaymetInfo(): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/getPaymentInfo`);
  }

  /**
  * @function getPaymetList
  * @description 
  */
  getPaymetList(reqBody): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/getPaymentList`, reqBody);
  }


  /**
   * @function getPaymetList
   * @description 
   */
  getAllFrequency(): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/getAllFrequency`);
  }

  createPaymentLink(reqBody): Observable<any> {
    return this.http.post(`${this.baseUrl}/watchboard/createUserPaymentLink`, reqBody);
  }

  getPaymentLinks(reqBody): Observable<any>{
    return this.http.post(`${this.baseUrl}/watchboard/getPaymentLinksForUser`, reqBody);
  }


  getUpcomingPayments(reqBody): Observable<any>{
    return this.http.post(`${this.baseUrl}/watchboard/getUpcomingPayments`, reqBody);
  }

  /**
    * @function updateExpire
    * @description 
    */
  updateExpire(reqBody): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/updatePaymentExpire`, reqBody);
  }
}
