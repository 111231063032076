import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { PageTitleService } from 'src/app/services/page-title.service';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit {

  createUserForm;

  roles = [
    {
      id: 1,
      role: "boAdmin"
    },
    {
      id: 2,
      role: "CS role"
    },
    {
      id: 3,
      role: "Trade Role"
    },
    {
      id: 4,
      role: "Marketing role"
    }
  ];

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private router: Router
  ) { }

  /**
   * @function ngOnInit
   * @description Set header title and initialize the createUserForm
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.pageTitleService.setTitle('User Management');

    this.createUserForm = new FormGroup({
      name: new FormControl("", Validators.compose([Validators.required])),
      email: new FormControl("", Validators.compose([Validators.required, Validators.pattern("[^ @]*@[^ @]*")])),
      phone: new FormControl("", Validators.compose([Validators.required])),
      password: new FormControl("", Validators.compose([Validators.required])),
      role: new FormControl("", Validators.compose([Validators.required])),
      status: new FormControl("ACTIVE")
    });

  }

  /**
   * @function goToUserManagementPage
   * @description navigate to create user management page
   */
  goToUserManagementPage() {
    this.router.navigate(["/dashboard/user-management/admin-list"]);
  }

  /**
   * @function onCreateUser
   * @description navigate to create admin list page
   */
  onCreateUser() {
    console.log(this.createUserForm.value);
    this.router.navigate(["/dashboard/user-management/admin-list"]);
  }

}
