import { Component, OnInit } from '@angular/core';
import { NotificationModalComponent } from '../watchboard/notification-modal/notification-modal.component';
import { NgbModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { WatchboardService } from '../../services/watchboard/watchboard.service';

@Component({
  selector: 'app-auto-push-notification',
  templateUrl: './auto-push-notification.component.html',
  styleUrls: ['./auto-push-notification.component.scss']
})
export class AutoPushNotificationComponent implements OnInit {
  notificationModaloptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    centered: true,
    size: 'lg',
  };
  userFilter = {
    currenPage: 1,
    limit: 0,
    skip: 0,
    brokerName: [],
    isCount: null,
    tavagaId: null,
    firstName: null,
    lastName: null,
    deviceType: null,
    isPhone: null,
    isEmail: null,
    isRiskSet: null,
    isGoalCreated: null,
    isTransactionId: null,
    isOnlineKyc: null,
    isOfflineKyc: null,
    emailAddress: null,
  };
  autoNotification = [];
  showEdit: number = -1;
  constructor(private ngmodalService: NgbModal, private watchboardService: WatchboardService) { }

  ngOnInit() {
    this.getAutoNotification();
  }


  getAutoNotification() {
    this.watchboardService.getAutoNotification().subscribe((res) => {
      this.autoNotification = res;
    })
  }

  openNotificationDialog() {
    const confirmModalRef = this.ngmodalService.open(NotificationModalComponent, this.notificationModaloptions);
    confirmModalRef.componentInstance.filterSearch = this.userFilter;
    confirmModalRef.componentInstance.sendObject = true;
    confirmModalRef.result.then((result) => {
      result && this.addNotification(result);
    }, (reason) => { });
  }


  addNotification(postBody: any) {
    if (postBody) {
      let post = {
        notificationObject: postBody.data,
        communicationType: postBody.communicationNotification,
        filters: postBody.filter,
        isActive: true
      }
      this.watchboardService.addAutoNotification(post).subscribe((res) => {
        this.getAutoNotification();
      })
    }
  }

  editNotification(notificationId: number) {
    this.showEdit = notificationId;
  }

  cancelNotification() {
    this.showEdit = -1;
  }

  saveNotification(notificationId: number) {
    //@ts-ignore
    let title = document.getElementById(`title_${notificationId}`).value;
    //@ts-ignore
    let body = document.getElementById(`body_${notificationId}`).value;
    //@ts-ignore
    let isActive = document.getElementById(`isActive_${notificationId}`).value;
    this.updateNotification(notificationId, title, body, isActive);
  }

  updateNotification(notificationId, title, body, isActive) {
    let postBody = this.autoNotification.find((a) => a.id == notificationId);
    if (postBody) {
      postBody.notificationObject.title = title ? title : postBody.notificationObject.title;
      postBody.isActive = isActive;
      postBody.notificationObject.body = body ? body : postBody.notificationObject.body;
      this.watchboardService.putAutoNotification(postBody).subscribe((res) => {
        this.cancelNotification();
        this.getAutoNotification();
      });
    }
  }

  deleteNotification(notificationId: number) {
    this.updateNotification(notificationId, "", "", false);
  }
}
