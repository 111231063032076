import { Component, OnInit } from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit {

  tavagaUserId: any;
  isVisible: boolean = false;

  userData: {
    user_id: number;
    start_date: string;
    end_date: string
  };

  goalData = [];
  filterData = [];
  tradeData: any;

  // for checking checkbox
  isChecked1 = false;
  isChecked2 = false;

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private spinnerService: SpinnerService,
    private router: Router,
    private toastService: ToastService,
    private fb: FormBuilder
  ) { }

  /**
   * @function ngOnInit
   * @description Set header title
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;

    this.pageTitleService.setTitle('User Wise Trading Details');

    const tradeObj = JSON.parse(localStorage.getItem("searched_tavaga_user"));
    if (tradeObj != null) {
      this.getTrade(tradeObj);
    }
  }

  onChange1(value1: boolean) {
    this.isChecked1 = value1;

    if (this.isChecked1 === true) {
      this.isChecked2 = false;

      this.goalData = this.filterData.filter((item) => {
        return item.goalStatus === "ACTIVE";
      })
    }
    else if (this.isChecked1 === false) {
      this.goalData = this.filterData;
    }

  }

  onChange2(value2: boolean) {
    this.isChecked2 = value2;

    if (this.isChecked2 === true) {
      this.isChecked1 = false;

      this.goalData = this.filterData.filter((item) => {
        return item.goalStatus === "DELETED";
      })
    } else if (this.isChecked2 === false) {
      this.goalData = this.filterData;
    }

  }

  /**
   * @function showTradeDetails
   * @description show trade details of selected tavaga-id
   * @param tavagaId
   */
  showTradeDetails(tavagaId) {

    this.userData = {
      user_id: tavagaId,
      start_date: "2019-04-09",
      end_date: "2019-04-10"
    };

    if (JSON.parse(localStorage.getItem("searched_tavaga_user")) !== null) {
      localStorage.removeItem("searched_tavaga_user");
      localStorage.setItem("searched_tavaga_user", JSON.stringify(this.userData));
    } else {
      localStorage.setItem("searched_tavaga_user", JSON.stringify(this.userData));
    }

    this.getTrade(this.userData);
  }

  /**
   * @function getTrade
   * @description get trades of selected tavaga-id and date-range
   * @param tavagstoredUserDataaId
   */
  getTrade(storedUserData) {
    this.spinnerService.start();
    this.userService.getTradingDetails(storedUserData).subscribe((res) => {
      this.tavagaUserId = res['id'];

      if (res) {
        this.tradeData = res;
      }

      if (res !== null && res['goals'].length > 0) {
        this.goalData = this.dataOperation(res['goals']);
        this.filterData = this.goalData;
        this.isVisible = true;
        this.spinnerService.stop();
      } else {
        let user = JSON.parse(localStorage.getItem("searched_tavaga_user"));
        this.toastService.show('No Goals Found For This User ID : ' + user.user_id, { classname: 'bg-danger text-light' })
        this.goalData = [];
        this.filterData = [];
        this.isVisible = false;
        this.spinnerService.stop();
      }
    },
      err => {
        console.log("error: ", err);
        this.spinnerService.stop();
      })

  }

  /**
   * @function dataOperation
   * @description set image to each trade on the basis of their goalType
   * @param goalObj
   */
  dataOperation(goalObj) {
    let baseUrl = "../assets/trade-images/";

    goalObj.forEach(item => {
      switch (item.goalType) {
        case 0:
          item.image = baseUrl + "wealth_max.png";
          break;
        case 1:
          item.image = baseUrl + "emergency_fund.png";
          break;
        case 2:
          item.image = baseUrl + "house.png";
          break;
        case 3:
          item.image = baseUrl + "education.png";
          break;
        case 4:
          item.image = baseUrl + "cricket_worldcup.png";
          break;
        case 5:
          item.image = baseUrl + "football_worldcup.png";
          break;
        case 6:
          item.image = baseUrl + "foreign_trip.png";
          break;
        case 7:
          item.image = baseUrl + "swag.png";
          break;
        case 8:
          item.image = baseUrl + "car.png";
          break;
        case 9:
          item.image = baseUrl + "wedding.png";
          break;
        case 10:
          item.image = baseUrl + "custom_goal.png";
          break;
        case 11:
          item.image = baseUrl + "jewellery.png";
          break;
        default:
          break;
      }
    });

    return goalObj;
  }

  /**
   * @function downloadReport
   * @description download report in excel
   */
  downloadReport() {
    let report = [...this.goalData];
    let reportArr = [];
    report.forEach(element => {
      reportArr.push({
        NAME: element.name ? element.name : '-',
        GOAL_STATUS: element.goalStatus ? element.goalStatus : '-',
        TARGET_AMOUNT: element.targetAmount ? element.targetAmount : '-',
        MONTHALY_AMOUNT: element.monthlyAmount ? element.monthlyAmount : '-',
        INVESTED_AMOUNT: element.investedAmount ? element.investedAmount : '-',
        TIME_TO_GOAL: element.timeToGoal ? element.timeToGoal : '-',
        PROFIT_AND_LOSS: element.profitAndLoss ? element.profitAndLoss : '-',
        GOAL_TYPE: element.goalType ? element.goalType : '-',
        ID: element.id ? element.id : '-',
        USER_ID: element.userId ? element.userId : '-',
        IMAGE: element.image ? element.image : '-',
      });
    });

    this.excelService.exportAsExcelFile(reportArr, new Date().toISOString().slice(0, 10) + "-user-" + this.tavagaUserId + "-goal-details");
  }

  /**
   * @function goToEditGoalPage
   * @description navigate to edit goal page
   */
  goToEditGoalPage(selectedData) {
    this.userService.setUserGoalData(selectedData);
    this.router.navigate(["dashboard/reports/trading-details/edit-goals"]);
  }

}
