import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class CoupounService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  /**
  * @function getCoupounList
  * @description 
  */
  getCoupons(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/coupons/fetch`, reqBody);
  }

  getCouponsCount(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/coupons/fetchCount`, reqBody);
  }

  checkCouponExists(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/coupons/checkExists`, reqBody);
  }

  getPaymentPackage(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/watchboard/calculatePaymentPackage`, reqBody);
  }


  addCoupoun(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/coupons/add`, reqBody);
  }

  editCoupoun(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/coupons/edit`, reqBody);
  }


  expireCoupoun(reqBody: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/coupons/expire`, reqBody);
  }
}
