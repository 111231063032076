import {
  Component,
  OnInit
} from '@angular/core';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.scss']
})
export class ChargesComponent implements OnInit {

  brokerData: any[];

  constructor(
    private pageTitleService: PageTitleService
  ) { }

  /**
   * @function ngOnInit
   * @description for set header title
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.pageTitleService.setTitle('Broker');

    this.brokerData = [
      { id: 1, broker: 'Edelweiss', brokerage: '1', transcharge: '0', gst: '0', stampduty: '0', sebitrans: '0', stt: '0' },
      { id: 2, broker: 'Zerodha', brokerage: '2', transcharge: '0', gst: '0', stampduty: '0', sebitrans: '0', stt: '0' },
      { id: 3, broker: 'Upstox', brokerage: '2', transcharge: '0', gst: '0', stampduty: '0', sebitrans: '0', stt: '0' }
    ];

  }

}
