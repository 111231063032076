import { 
  Component, 
  OnInit
 } from '@angular/core';
import { 
  NgxDrpOptions, 
  PresetItem, 
  Range 
} from 'ngx-mat-daterange-picker';
import { PageTitleService } from 'src/app/services/page-title.service';
import { UserService } from 'src/app/services/user.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';
import * as moment from "moment";
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToastService } from 'src/app/core/shared/services/toast.service';

@Component({
  selector: 'app-client-activation-report',
  templateUrl: './client-activation-report.component.html',
  styleUrls: ['./client-activation-report.component.scss']
})
export class ClientActivationReportComponent implements OnInit {

  data: any[];
  filterQuery = "";
  rowsOnPage = 20;

  // date range picker related
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  range: Range = { fromDate: new Date(), toDate: new Date() };

  constructor(
    private pageTitleService: PageTitleService,
    private userService: UserService,
    private excelService: ExcelService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private router: Router) { }

  /**
   * @function ngOnInit
   * @description Set header title
   */
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.pageTitleService.setTitle('Daily Client Activation Summary');

    const today = new Date();
    const yester = new Date(new Date().setDate(new Date().getDate() - 30));
    this.setupPresets();

    this.options = {
      presets: this.presets,
      format: "mediumDate",
      range: {
        fromDate: yester,
        toDate: today
      },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false
      }
    };

  }

  /**
   * @function getActivatedClient
   * @description get activated client details of selected daterange
   * @param dateObj
   */
  getActivatedClient(dateObj) {
    this.spinnerService.start();
    this.userService.getActivatedClient(dateObj).subscribe(
      data => {
        if (data["dailyReport"].length > 0) {
          this.data = data["dailyReport"];
          this.spinnerService.stop();
        } else {
          this.data = [];
          this.toastService.show(`no record found`, { classname: "bg-danger text-light", delay: 5000 });
          this.spinnerService.stop();
        }
      },
      err => {
        this.spinnerService.stop();
      }
    );
  }

  /**
   * @function updateRange
   * @description handler function that receives the updated date range object
   * @param range
   */
  updateRange(range: Range) {
    this.range = range;
    let dateObj = {
      fromDate: moment(this.range.fromDate).format("YYYY-MM-DD"),
      toDate: moment(this.range.toDate).format("YYYY-MM-DD")
    };
    this.getActivatedClient(dateObj);
  }

  /**
   * @function setupPresets
   * @description helper function to create initial presets
   */
  setupPresets() {
    const backDate = numOfDays => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: "Yesterday",
        range: { fromDate: yesterday, toDate: today }
      },
      {
        presetLabel: "Last 7 Days",
        range: { fromDate: minus7, toDate: today }
      },
      {
        presetLabel: "Last 30 Days",
        range: { fromDate: minus30, toDate: today }
      },
      {
        presetLabel: "This Month",
        range: { fromDate: currMonthStart, toDate: currMonthEnd }
      },
      {
        presetLabel: "Last Month",
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }

  /**
   * @function downloadReport
   * @description download report in excel
   */
  downloadReport() {
    let report = [...this.data];
    let reportArr = [];
    report.forEach(element => {
      reportArr.push({
        TAVEGA_ID: element.id ? element.id : '-',
        FIRST_NAME: element.givenName ? element.givenName : '-',
        LAST_NAME: element.familyName ? element.familyName : '-',
        PHONE: element.phoneCode ? element.phoneCode : '-' + " " + element.phone ? element.phone : '-',
        BROKER_ID: element.brokerId ? element.brokerId : '-',
        BROKER: element.brokerName ? element.brokerName : '-',
        EMAIL: element.email ? element.email : '-',
        CREATED_DATE: moment(element.onboardingStatus.createdAt).format('MMMM Do YYYY, h:mm:ss a') ? moment(element.onboardingStatus.createdAt).format('MMMM Do YYYY, h:mm:ss a') : '-',
        ACTIVATION_DATE: moment(element.onboardingStatus.lastUpdated).format('MMMM Do YYYY, h:mm:ss a') ? moment(element.onboardingStatus.lastUpdated).format('MMMM Do YYYY, h:mm:ss a') : '-',
        STATUS: element.status ? element.status : '-'
      });
    });

    this.excelService.exportAsExcelFile(reportArr, moment(this.range.fromDate).format("YYYY-MM-DD") +
      "-to-" + moment(this.range.toDate).format("YYYY-MM-DD") + "-activated-customer-list");
  }

}
