import {
  Component,
  OnInit,
  Output,
  OnChanges,
  EventEmitter
} from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { PageTitleService } from "src/app/services/page-title.service";
import { UserService } from 'src/app/services/user.service';

declare let $: any;
@Component({
  selector: "app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"]
})
export class AppHeaderComponent implements OnInit {
  pageTitle: string;
  @Output() onToggleSidebar = new EventEmitter();
  adminFilter = { limit: 10, skip: 0 };
  adminNotification = [];
  notificationApiCall = false;

  constructor(
    private pageTitleService: PageTitleService,
    private auth: AuthService,
    private userService: UserService,
  ) { }

  /**
   * @function ngOnInit
   * @description for set header title
   */
  ngOnInit() {
    this.pageTitleService.title.subscribe((res: string) => {
      this.pageTitle = res;
    });
    this.getAdminNotification();
    this.scrollNotificationEvent();
  }

  ngOnChanges() {
    this.getAdminNotification();
  }

  /**
   * @function onLogout
   * @description for logout
   */
  onLogout() {
    this.auth.logout();
  }

  /**
   * @function toggleSidebar
   * @description use for toggle the sidebar
   */
  toggleSidebar() {
    this.onToggleSidebar.emit();
  }

  //Pass shouldUpdate if you want to push notification else overwrite
  getAdminNotification(shouldUpdate: boolean = true) {
    this.notificationApiCall = true;
    this.userService.getAdminNotification(this.adminFilter).subscribe((res) => {
      this.notificationApiCall = false;
      let rempD = res && res.args.status_code == 1 ? res.args.data : [];
      if (rempD.length) {
        if (shouldUpdate) { this.adminNotification.push(...rempD) }
        else this.adminNotification = rempD;
      }
    });
  }

  scrollNotificationEvent() {
    $('#notification').on('scroll', () => {
      if ($('#notification').scrollTop() + $('#notification').innerHeight() >= $('#notification')[0].scrollHeight) {
        this.adminFilter.skip = this.adminNotification.length;
        this.getAdminNotification();
      }
    })
  }

  getLatestNotification() {
    this.adminFilter = { limit: 10, skip: 0 };
    this.getAdminNotification(false);
  }
}
