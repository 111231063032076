import { Component, OnInit } from '@angular/core';
import { NotificationModalComponent } from '../watchboard/notification-modal/notification-modal.component';
import { NgbModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { WatchboardService } from '../../services/watchboard/watchboard.service';
import * as moment from "moment";
import { CoupounService } from '../../services/coupoun/coupoun.service';
import { ToastService } from '../../core/shared/services/toast.service';
import { PaymentService } from '../../services/payment/payment.service';
import { CrudDiscountComponent } from './crud-discount/crud-discount.component';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

declare let $: any;
@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})

export class DiscountComponent implements OnInit {
  notificationModaloptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true,
    centered: true,
    size: 'lg',
  };
  limitPayment = 10;
  coupounQuery = { discountCode: '', companyName: '', coupounStartAt: '', coupounEndAt: '', limit: this.limitPayment, skip: 0, currenPage: 1 };
  coupounList = [];
  filterSearch = new Subject<string>();
  coupounCount = 0;
  deleteCoupounInfo: any;
  activePackage = {};
  paymentFrequency = [];
  constructor(private ngmodalService: NgbModal, private paymentService: PaymentService, private coupounService: CoupounService, private toastService: ToastService) { }


  ngOnInit() {
    this.getData();
    this.debounceSearch();
    this.getAllFrequency();
  }

  getData() {
    this.getCoupounCount();
    this.getCoupoun();
  }

  getCoupoun() {
    this.coupounService.getCoupons(this.coupounQuery).subscribe((res) => {
      this.coupounList = res && res.isSuccess ? res.data : [];
    })
  }

  getCoupounCount() {
    let query: any = JSON.parse(JSON.stringify(this.coupounQuery));
    this.coupounService.getCouponsCount(query).subscribe((res) => {
      this.coupounCount = res && res.isSuccess ? res.data : 0;
    })
  }

  openCoupounDialog() {
    const confirmModalRef = this.ngmodalService.open(CrudDiscountComponent, this.notificationModaloptions);
    confirmModalRef.componentInstance.activePackage = this.activePackage;
    confirmModalRef.componentInstance.paymentFrequency = this.paymentFrequency;
    confirmModalRef.result.then((result) => {
      if (result && result.isSuccess) {
        this.getData();
        this.toastService.show('Added Sucessfully', { classname: "bg-success text-light", delay: 5000 });
      } else {
        this.toastService.show(result.error.message, { classname: "bg-danger text-light", delay: 5000 });
      }

    }, (reason) => {

    });
  }

  editCoupoun(coupoun) {
    if (coupoun) {
      const confirmModalRef = this.ngmodalService.open(CrudDiscountComponent, this.notificationModaloptions);
      confirmModalRef.componentInstance.coupounObj = coupoun;
      confirmModalRef.componentInstance.activePackage = this.activePackage;
      confirmModalRef.componentInstance.paymentFrequency = this.paymentFrequency;
      confirmModalRef.result.then((result) => {
        if (result && result.isSuccess) {
          this.getData();
          this.toastService.show('Coupon Edited Sucessfully', { classname: "bg-success text-light", delay: 5000 });
        } else {
          this.toastService.show(result.error.message, { classname: "bg-danger text-light", delay: 5000 });
        }

      }, (reason) => {
        
      });
    }
  }

  debounceSearch() {
    // Debounce search.
    this.filterSearch.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.coupounQuery.currenPage = 1;
        this.coupounQuery.limit = this.limitPayment;
        this.coupounQuery.skip = 0;
        this.getData();
      });
  }

  expireCoupounMsg(coupoun) {
    this.deleteCoupounInfo = coupoun;
    $('#deleteCoupoun').modal('toggle');

  }

  deleteCoupoun() {
    this.deleteCoupounInfo.id && this.coupounService.expireCoupoun({ id: this.deleteCoupounInfo.id }).subscribe((res) => {
      $('#deleteCoupoun').modal('toggle');
      this.getData();
    })
  }

  getAllFrequency() {
    this.paymentService.getAllFrequency().subscribe((res) => {
      this.paymentFrequency = res && res.statusCode ? res.data : [];
      this.activePackage = this.paymentFrequency.length ? this.paymentFrequency.find((p) => p.isActive) : this.activePackage;
    })
  }

  onPageChange(pageIndex: any) {
    this.coupounQuery.currenPage = pageIndex;
    this.coupounQuery.skip = (pageIndex - 1) * this.coupounQuery.limit;
    this.getCoupoun();

  }
}
