import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {

  /**
   * @function transform
   * @description get the query and serch this query in array and return new array
   * @param array 
   * @param query 
   */
  transform(array: any[], query: string): any {

    if (!query || query.trim() === '') {
      return array;
    } else {
      return this.filterElements(array, query.trim())
    }

  }

  filterElements(array, query): any {

    const containsDeep = (text) => (value) => {
      if (!value) return false;
      const valueType = typeof value;

      if (valueType === 'string' || valueType === 'number') {
        return value.toString().toLowerCase().indexOf(text.toString().toLowerCase()) > -1;
      }
      if (Array.isArray(value)) {
        return value.some(containsDeep(text));
      }
      if (valueType === 'object') {
        return Object.values(value).some(containsDeep(text));
      }
      return false;
    };

    let filtered = array.filter(containsDeep(query));

    return filtered;
  }

}