import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { FlexLayoutModule } from "@angular/flex-layout";
import { DataTableModule } from "angular-6-datatable";
import { NgxMatDrpModule } from "ngx-mat-daterange-picker";
import { NgxPaginationModule } from 'ngx-pagination';
import { QuillModule } from "ngx-quill";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./core/shared.module";

import { httpInterceptor } from "./interceptors/httpInterceptor";
import { DataFilterPipe } from "./pipes/data-filter.pipe";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AppHeaderComponent } from "./dashboard/app-header/app-header.component";
import { WatchboardComponent } from "./dashboard/watchboard/watchboard.component";
import { NotificationModalComponent } from "./dashboard/watchboard/notification-modal/notification-modal.component";
import { MultipleFilterModalComponent } from './dashboard/watchboard/multiple-filter-modal/multiple-filter-modal.component';

import { LeadsComponent } from "./dashboard/leads/leads.component";
import { EditUserModalComponent } from "./dashboard/leads/edit-user-modal/edit-user-modal.component";

import { CashManagementComponent } from "./dashboard/cash-management/cash-management.component";
import { CashFilterModalComponent } from './dashboard/cash-management/cash-filter-modal/cash-filter-modal.component';
import { TransactionFilterModalComponent } from './dashboard/cash-management/transaction-filter-modal/transaction-filter-modal.component';
import { EtfPriceUpdateModalComponent } from './dashboard/cash-management/etf-price-update-modal/etf-price-update-modal.component';

import { TradeTerminalComponent } from "./dashboard/trade-terminal/trade-terminal.component";
import { NewTradeFilterModalComponent } from './dashboard/trade-terminal/new-trade-filter-modal/new-trade-filter-modal.component';
import { PendingTradeFilterModalComponent } from './dashboard/trade-terminal/pending-trade-filter-modal/pending-trade-filter-modal.component';

import { ReportsComponent } from "./dashboard/reports/reports.component";
import { ClientActivationReportComponent } from "./dashboard/reports/client-activation-report/client-activation-report.component";
import { AumReportComponent } from "./dashboard/reports/aum-report/aum-report.component";
import { GoalCountReportComponent } from "./dashboard/reports/goal-count-report/goal-count-report.component";
import { TradingDetailsComponent } from "./dashboard/reports/trading-details/trading-details.component";
import { EditGoalComponent } from "./dashboard/reports/trading-details/edit-goal/edit-goal.component";
import { GoalsComponent } from "./dashboard/reports/trading-details/goals/goals.component";
import { TradesComponent } from "./dashboard/reports/trading-details/trades/trades.component";

import { GoalManagementComponent } from './dashboard/goal-management/goal-management.component';
import { ProcessingEditGoalsComponent } from './dashboard/goal-management/processing-edit-goals/processing-edit-goals.component';
import { GoalDetailsComponent } from './dashboard/goal-management/goal-details/goal-details.component';

import { UserManagementComponent } from "./dashboard/user-management/user-management.component";
import { AdminListComponent } from "./dashboard/user-management/admin-list/admin-list.component";
import { CreateNewUserComponent } from "./dashboard/user-management/create-new-user/create-new-user.component";

import { ChargesComponent } from "./dashboard/charges/charges.component";
import { TradePriceUpdateModalComponent } from './dashboard/goal-management/processing-edit-goals/trade-price-update-modal/trade-price-update-modal.component';
import { BasketAllocationsComponent } from './dashboard/basket-allocations/basket-allocations.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { AutoPushNotificationComponent } from './dashboard/auto-push-notification/auto-push-notification.component';
import { PaymentComponent } from './dashboard/payment/payment.component';
import { UpdatePaymentComponent } from './dashboard/update-payment/update-payment.component';
import { UpdatePaymentModalComponent } from './dashboard/update-payment/update-payment-modal/update-payment-modal.component';
import { DiscountComponent } from './dashboard/discount/discount.component';
import { CrudDiscountComponent } from './dashboard/discount/crud-discount/crud-discount.component';
import { MatTabsModule } from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import { CreatePaymentLinkModalComponent } from './dashboard/leads/create-payment-link-modal/create-payment-link-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AppHeaderComponent,
    WatchboardComponent,
    NotificationModalComponent,
    MultipleFilterModalComponent,
    LeadsComponent,
    EditUserModalComponent,
    CashManagementComponent,
    CashFilterModalComponent,
    TransactionFilterModalComponent,
    EtfPriceUpdateModalComponent,
    TradeTerminalComponent,
    NewTradeFilterModalComponent,
    PendingTradeFilterModalComponent,
    ReportsComponent,
    ClientActivationReportComponent,
    AumReportComponent,
    GoalCountReportComponent,
    TradingDetailsComponent,
    TradesComponent,
    GoalsComponent,
    EditGoalComponent,
    GoalManagementComponent,
    GoalDetailsComponent,
    ProcessingEditGoalsComponent,
    UserManagementComponent,
    AdminListComponent,
    CreateNewUserComponent,
    ChargesComponent,

    DataFilterPipe,

    TradePriceUpdateModalComponent,

    BasketAllocationsComponent,

    TimeAgoPipe,

    AutoPushNotificationComponent,

    PaymentComponent,

    UpdatePaymentComponent,

    UpdatePaymentModalComponent,

    DiscountComponent,

    CrudDiscountComponent,

    CreatePaymentLinkModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    NgxMatDrpModule,
    NgxPaginationModule,
    MatTabsModule,
    MatIconModule,
    DataTableModule,
    QuillModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    NotificationModalComponent,
    MultipleFilterModalComponent,
    EditUserModalComponent,
    CreatePaymentLinkModalComponent, 
    CashFilterModalComponent,
    TransactionFilterModalComponent,
    EtfPriceUpdateModalComponent,
    NewTradeFilterModalComponent,
    PendingTradeFilterModalComponent,
    TradePriceUpdateModalComponent,
    UpdatePaymentModalComponent,
    CrudDiscountComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
