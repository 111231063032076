import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  count = 0;

  constructor() { }

  loading = new BehaviorSubject(false);

  start() {
    this.count++;
    this.loading.next(true);
  }

  stop() {
    this.count--;
    if (this.count === 0) {
      this.loading.next(false);
    }
  }

}
