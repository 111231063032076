import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-etf-price-update-modal',
  templateUrl: './etf-price-update-modal.component.html',
  styleUrls: ['./etf-price-update-modal.component.scss']
})
export class EtfPriceUpdateModalComponent implements OnInit {

  @Input() etfData = [];
  @Output() userOptionData = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  /**
   * @function dataPassToParent
   * @description stored data send to parent via emit() method
   */
  dataPassToParent(etfData: Array<any>) {
    this.userOptionData.emit(etfData);
    this.activeModal.close('Ok click');
  }

}
