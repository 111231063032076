import { Component, OnInit } from '@angular/core';
import { PaymentService } from "../../services/payment/payment.service";
import * as moment from "moment";
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor(private paymentService: PaymentService) { }
  paymentList = [];
  paymentCount = 0;
  paymentFrequency = [];
  limitPayment = 10;
  paymentInfo: any = { todayC: 0, yesterdayC: 0, monthC: 0, lmonthC: 0, yearC: 0, lyearC: 0 };
  paymentQuery = { userId: '', processingPayment: '', isExpired: '', remark: '', orderId: '', from: moment().startOf('date').valueOf(), to: moment().endOf('date').valueOf(), limit: this.limitPayment, skip: 0, currenPage: 1 };
  dateFilter = [];
  filterSearch = new Subject<string>();
  dateRange = [
    { key: 'Today', value: 'today' },
    { key: 'Yesterday', value: 'yesterday' },
    { key: 'This Month', value: 'month' },
    { key: 'Previou Month', value: 'previousMonth' },
    { key: 'This Year', value: 'year' },
    { key: 'Previou Year', value: 'previousYear' }
  ];
  expire = [
    { key: '--', value: null },
    { key: 'Yes', value: true },
    { key: 'No', value: false }

  ]
  apiCall: boolean = false;

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getAllPaymentFrequency();
    this.getPaymentInfo();
    this.getPaymentCount();
    this.getPaymentList();
    this.debounceSearch();
    this.prepareDates();
  }

  getPaymentInfo() {
    this.paymentService.getPaymetInfo().subscribe((res) => {
      let paymentInfo = res && res.statusCode ? res.data : {};
      this.calculatePaymentMatrix(paymentInfo);
    })
  }

  calculatePaymentMatrix(paymentInfo) {
    let currentDate = moment();
    let currentDay = currentDate.date();
    let currentMonth = currentDate.month() + 1;
    let currentYear = currentDate.year();
    let yesterDay = currentDate.add(-1, 'days').date();
    let lastMonth = currentDate.add(-1, 'months').month() + 1;
    let lastYear = currentDate.add(-1, 'years').year();

    paymentInfo && paymentInfo.forEach((p) => {
      if (currentDay == p.dayAt && currentMonth == p.monthAt && currentYear == p.yearAt) {
        this.paymentInfo.todayC += p.noOfPayments;
      }

      if (yesterDay == p.dayAt && currentMonth == p.monthAt && currentYear == p.yearAt) {
        this.paymentInfo.yesterdayC += p.noOfPayments;
      }

      if (currentMonth == p.monthAt && currentYear == p.yearAt) {
        this.paymentInfo.monthC += p.noOfPayments;
      }

      if (lastMonth == p.monthAt && currentYear == p.yearAt) {
        this.paymentInfo.lmonthC += p.noOfPayments;
      }

      if (currentYear == p.yearAt) {
        this.paymentInfo.yearC += p.noOfPayments;
      }

      if (lastYear == p.yearAt) {
        this.paymentInfo.lyearC += p.noOfPayments;
      }
    });
  }


  getPaymentList() {
    this.apiCall = true;
    this.paymentService.getPaymetList(this.paymentQuery).subscribe((res) => {
      this.paymentList = res && res.statusCode ? res.data : [];
      this.apiCall = false;
    })
  }

  getPaymentCount() {
    let query: any = JSON.parse(JSON.stringify(this.paymentQuery));
    query.isCount = true;
    this.paymentService.getPaymetList(query).subscribe((res) => {
      this.paymentCount = res && res.statusCode ? res.data : 0;
    })
  }

  onPageChange(pageIndex: any) {
    this.paymentQuery.currenPage = pageIndex;
    this.paymentQuery.skip = (pageIndex - 1) * this.paymentQuery.limit;
    this.getPaymentList();
  }

  getAllPaymentFrequency() {
    this.paymentService.getAllFrequency().subscribe((res) => {
      this.paymentFrequency = res && res.statusCode ? res.data : [];
    })
  }

  getPaymentFreById(id: number) {
    let freq = this.paymentFrequency.find((p) => p.id == id);
    return freq ? freq.frequency : '';
  }

  debounceSearch() {
    // Debounce search.
    this.filterSearch.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.paymentQuery.currenPage = 1;
        this.paymentQuery.limit = this.limitPayment;
        this.paymentQuery.skip = 0;
        this.getPaymentCount();
        this.getPaymentList();
      });
  }

  prepareDates() {
    let todayStart = moment().startOf('day').valueOf();
    let todayEnd = moment().endOf('day').valueOf();
    let yesterdayStart = moment().add(-1, 'days').startOf('day').valueOf();
    let yesterdayEndDay = moment().add(-1, 'days').endOf('day').valueOf();
    let monthStart = moment().startOf('months').valueOf();
    let monthEnd = moment().endOf('months').valueOf();
    let previousMonthStart = moment().add(-1, 'months').startOf('months').valueOf();
    let previousMonthEnd = moment().add(-1, 'months').endOf('months').valueOf();
    let yearStart = moment().startOf('years').valueOf();
    let yearEnd = moment().endOf('years').valueOf();
    let previousYearStart = moment().add(-1, 'years').startOf('years').valueOf();
    let previousYearEnd = moment().add(-1, 'years').endOf('years').valueOf();


    this.dateFilter['today'] = { from: todayStart, to: todayEnd };
    this.dateFilter['yesterday'] = { from: yesterdayStart, to: yesterdayEndDay };
    this.dateFilter['month'] = { from: monthStart, to: monthEnd };
    this.dateFilter['previousMonth'] = { from: previousMonthStart, to: previousMonthEnd };
    this.dateFilter['year'] = { from: yearStart, to: yearEnd };
    this.dateFilter['previousYear'] = { from: previousYearStart, to: previousYearEnd };
  }

  changeDate(event) {
    let { value } = event.target;
    let dateRange = this.dateFilter[value];
    this.paymentQuery.from = dateRange.from;
    this.paymentQuery.to = dateRange.to;
    this.getPaymentCount();
    this.getPaymentList();
  }

  changeExpire(event) {
    let { value } = event.target;
    this.paymentQuery.isExpired = value;
    this.getPaymentCount();
    this.getPaymentList();
  }
}
